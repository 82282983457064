<template>
  <div>

    <loading-spinner :isLoading="loading" />

    <section class="container">
      <section class="section">
        <div class="project-title">
            <h2 class="title is-3">
				{{ this.researchProject.name }}
				<span
					v-if="this.researchProject.isSample"
					class="tag is-info is-light is-medium sample-tag">
						SAMPLE
				</span>
			</h2>
        </div>
        <hr />
      </section>

      <section class="section items">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card">
              <div class="card-content" @click="goToPage('articles')">
                <p class="title">
                  Articles
                </p>
                <div class="custom-media">
                  <figure class="image is-64x64">
                    <img src="../../assets/article.png" alt="Image">
                  </figure>
                </div>
                <p class="subtitle">
                  Create paper file, manage versions of your text from Overleaf, Google Drive or upload a local file.
                </p>
              </div>
            </div>
          </div>

          <div class="column is-one-third">
            <div class="card">
              <div class="card-content" @click="goToPage('experiments')">
                <p class="title">
                  Experiments
                </p>
                <div class="custom-media">
                  <figure class="image is-64x64">
                    <img src="../../assets/experiments.png" alt="Image">
                  </figure>
                </div>
                <p class="subtitle">
                  Make experiments, track your results, manage scripts, and evaluate your work.
                </p>
              </div>
            </div>
          </div>

          <div class="column is-one-third">
            <div class="card">
              <div class="card-content" @click="goToPage('related-works')">
                <p class="title">
                  Related Works
                </p>
                <div class="custom-media">
                  <figure class="image is-64x64">
                    <img src="../../assets/related_work.png" alt="Image">
                  </figure>
                </div>
                <p class="subtitle">
                  Save references, articles, video presentations, and export citations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-one-third is-offset-one-fifth">
            <div class="card">
				<div class="card-content" @click="goToPage('conferences')">
                <p class="title">
                  Conferences
                </p>
                <div class="custom-media">
                  <figure class="image is-64x64">
                    <img src="../../assets/conference-submission.png" alt="Image">
                  </figure>
                </div>
                <p class="subtitle">
                  Setup your paper for a conference. Track dead-lines, abstract texts, authors, and submission fees.
                </p>
              </div>
            </div>
          </div>

          <div class="column is-one-third">
            <div class="card">
              <div class="card-content">
                <p class="title">
					Docs & Files <br><span class="tag is-primary is-light is-small">Coming soon</span>
                </p>
                <div class="custom-media coming-soon">
                  <figure class="image is-64x64">
                    <img src="../../assets/docs-files.png" alt="Image">
                  </figure>
                </div>
                <p class="subtitle">
                  Share docs, images, and files. Organize them together so they are easy to find.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="lab-footer">
          <hr />
          <h5 class="title is-5">Welcome to: {{this.researchProject.name}}!</h5>
          <p>
            This is the place to do everything related to this research — make experiments, and get work done.
          </p>
          <p>
            <a href="https://www.youtube.com/watch?v=lnCyPevTDhY" target="_blank">Learn more about the tools above</a> • <a href="mailto:support@researchscale.io" target="_blank">Need a hand?</a>
          </p>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import humps from 'humps';

import LoadingSpinner from '../commons/LoadingSpinner.vue';

import router from '../../routes/router';
import api from '../../api';

import {
	errorMessage
} from '../../commons/constants';

export default {
	name: 'LabList',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			fetchDataFail: false,
			loading: false,
			errorMessage: errorMessage.FAILED_FETCH,
			researchProject: []
		};
	},
	async created() {
		this.loading = true;
		await this.getProject(this.$route.params.id);
		this.loading = false;
	},
	methods: {
		async getProject(id) {
			const { ok, data } = await api.researchProjects.getById(id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.researchProject = humps.camelizeKeys(data);
		},
		goToPage(page) {
			router.push({ path: `/${page}/${this.$route.params.id}` });
		}
	}
};
</script>

<style scoped>
.project-title {
    align-content: center;
}
.icon-button {
  margin-right: 5px;
}
.section.items {
  margin-top: -4rem;
}
.card {
  height: 100%;
  width: 100%;
}
.card-content {
  cursor: pointer;
}
.card-footer {
  height: 50px;
}
.card .title {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .subtitle {
  font-size: 0.9rem;
  min-height: 55px;
  max-height: 55px;

  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card .custom-media  {
    margin-bottom: 1em;
    padding-left: 3.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.coming-soon  {
    margin-top: -20px;
}
.lab-footer {
  text-align: center;
  font-size: 0.9rem;
}
</style>
