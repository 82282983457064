import * as base from '../base';

export async function signUp(user) {
	return await base.post('signup', {
		name: user.name,
		email: user.email,
		password: user.password
	});
};

export async function signIn(user) {
	return await base.post('signin', {
		email: user.email,
		password: user.password
	});
};

export async function getById(id) {
	return await base.get(`users/${id}`);
};

export async function recoverPassword(email) {
	return await base.post('users/recover-password', {
		email: email
	});
};

export async function checkResetPassword(resetPasswordToken) {
	return await base.post('users/check-password-reset', {
		resetPasswordToken: resetPasswordToken
	});
};

export async function resetPassword(resetPayload) {
	return await base.post('users/reset-password', {
		userId: resetPayload.userId,
		password: resetPayload.password
	});
};
