<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <h2 class="title is-3">Related Work</h2>
        <hr />
      </section>

      <section class="section form">
        <div class="columns">
          <div class="column is-two-thirds">
            <div v-if="saveDataFail" class="notification is-danger is-light has-text-centered">
              {{ errorMessage }}
          </div>

          <div class="field">
            <label class="label">What is the title of this work?</label>
            <div class="control">
              <input
                v-model="relatedWork.name"
                class="input"
                type="text"
                placeholder="Title of this work..."
              />
            </div>
            <p
              v-if="isFormInvalid"
              class="help is-danger"
            >
              Title cannot be empty
            </p>
          </div>

          <div class="field">
            <div class="file is-fullwidth has-name">
              <label class="file-label">
                <input
                  @change="uploadFile"
                  class="file-input"
                  type="file"
                  id="file-input"
                  ref="files"
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <font-awesome-icon class="icon-button" icon="upload" />
                  </span>
                  <span class="file-label">
                    Upload a reference paper…
                  </span>
                </span>
                <span class="file-name">
                  {{ fileName }}
                </span>
              </label>
            </div>

            <p
              v-if="isFileInvalid"
              class="help is-danger"
            >
              File size must not exceed 20 MB.
            </p>

            <p
              v-if="fileUploadFail"
              class="help is-danger"
            >
              Error uploading your file. Please try again.
            </p>

            <div class="field">
              <div class="label"></div>
              <div v-if="fileUploadStarted">
                <progress v-if="!fileUploadSuccess && !fileUploadFail" class="progress is-success is-primary" max="100">15%</progress>
                <progress v-if="fileUploadSuccess" class="progress is-success" value="100" max="100">100%</progress>
                <progress v-if="fileUploadFail" class="progress is-danger" value="90" max="100">90%</progress>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Paste the web link here (optional)</label>
            <div class="control">
              <p class="control has-icons-left">
                <input
                  v-model="relatedWork.externalLink"
                  class="input"
                  type="text"
                  placeholder="e.g. https://usenix.org/conference/..."
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon class="icon-button" icon="link" />
                </span>
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label">Paste the video link here (optional)</label>
            <div class="control">
              <p class="control has-icons-left">
                <input
                  v-model="relatedWork.videoLink"
                  class="input"
                  type="text"
                  placeholder="e.g. https://www.youtube.com/watch?..."
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon class="icon-button" icon="video" />
                </span>
              </p>
            </div>
          </div>

          <hr/>

          <div class="field">
            <label class="label">BibTeX - citation (optional)</label>
            <div class="control">
              <textarea
                v-model="relatedWork.bibtex"
                class="textarea"
                placeholder="e.g.
  @INPROCEEDINGS{9631451,
    author={Pantuza, Gustavo and Bleme, Lucas A. C. and Vieira, Marcos A. M. and Vieira, Luiz F. M.},
    booktitle={2021 IEEE Symposium on Computers and Communications (ISCC)},
    title={Danian: tail latency reduction of networking application through an O(1) scheduler},
    year={2021},
    volume={},
    number={},
    pages={1-6},
    doi={10.1109/ISCC53001.2021.9631451}}"
              ></textarea>
            </div>
          </div>

          <div class="field">
            <label class="label">Notes (optional)</label>
            <div class="control">
              <textarea
                v-model="relatedWork.description"
                class="textarea"
                placeholder="Key points of this related work, brief description or a note..."
              ></textarea>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <button
                @click="save"
                class="button is-link"
              >
                {{ this.$route.params.id ? 'Update this related work' : 'Create this related work' }}
              </button>
            </div>
            <div class="control">
              <button
                @click="goToRelatedWorksListPage"
                class="button is-link is-light"
              >Cancel</button>
            </div>
          </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import {
	errorMessage,
	entityType,
	fileValidation
} from '../../commons/constants';
import router from '../../routes/router';

export default {
	name: 'RelatedWorksDetails',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			loading: false,
			saveDataFail: false,
			isFormInvalid: false,
			isFileInvalid: false,
			errorMessage: errorMessage.FAILED_SAVE,
			fileNameUploaded: 'e.g. nsdi13-sdtrafficmeasurement.pdf',
			fileUploadFail: false,
			fileUploadSuccess: false,
			fileUploadStarted: false,
			relatedWork: {
				name: '',
				description: '',
				externalLink: '',
				videoLink: '',
				fileLink: '',
				bibtex: '',
				researchProjectId: ''
			}
		};
	},
	computed: {
		fileName() {
			return this.fileNameUploaded;
		}
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadEntityData();
		}

		this.relatedWork.researchProjectId = this.$route.params.researchProjectId;
	},
	methods: {
		validateForm() {
			this.isFormInvalid = false;

			if (this.relatedWork.name === undefined || this.relatedWork.name.length === 0) {
				this.isFormInvalid = true;
			}

			return !this.isFormInvalid;
		},
		async loadEntityData() {
			const { ok, data } = await api.relatedWorks.getById(this.$route.params.id);
			if (ok && data) {
				this.relatedWork = data;
				this.relatedWork.fileLink = data.file_link;
				this.relatedWork.externalLink = data.external_link;
				this.relatedWork.videoLink = data.video_link;

				/*
        * This is a workaround for extracting the file name from the S3 URL.
        * In the future: lets refactor the POST /related-works endpoint to save the original file name.
        * Then we could retrieve it from the GET /related-works/:id endpoint and remove this line.
        */
				if (this.relatedWork.fileLink) {
					this.fileNameUploaded = this.relatedWork.fileLink.split('/')[3].substring(33, this.relatedWork.fileLink.length);
				}
			}
		},
		goToRelatedWorksListPage() {
			router.push({ path: `/related-works/${this.relatedWork.researchProjectId}` });
		},
		async uploadFile() {
			this.fileUploadStarted = false;
			this.fileUploadSuccess = false;

			const fileData = this.$refs.files.files[0];
			this.fileNameUploaded = fileData.name;
			if (fileData.size > fileValidation.MAX_FILE_SIZE) {
				this.isFileInvalid = true;
				return;
			}

			this.fileUploadStarted = true;
			this.isFileInvalid = false;

			const formData = new FormData();
			formData.append('entityType', entityType.RELATED_WORK);
			formData.append('file', fileData);

			const { ok, data } = await api.files.upload(formData);
			if (!ok || !data) {
				this.saveDataFail = true;
				this.fileUploadSuccess = false;
				this.fileUploadFail = true;
				return;
			}

			this.relatedWork.fileLink = data.link;
			this.fileUploadSuccess = true;
		},
		async save() {
			if (!this.validateForm()) {
				return;
			}

			this.loading = true;
			if (!this.$route.params.id) {
				const { ok } = await api.relatedWorks.save(this.relatedWork);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your related work was created!',
					'success'
				);
			} else {
				const { ok } = await api.relatedWorks.update(this.relatedWork);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your related work was updated!',
					'success'
				);
			}

			this.loading = false;
			router.push({ path: `/related-works/${this.relatedWork.researchProjectId}` });
		}
	}
};
</script>

<style scoped>
.form {
  margin-top: -4rem;
}
</style>
