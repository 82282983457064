export const httpStatusCode = {
	SUCCESS: 200,
	INTERNAL_ERROR: 500,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403
};

export const errorMessage = {
	// Auth
	USER_ALREADY_EXIST: 'We found an existing user with this email. Try signing in.',
	USER_SIGNUP_FAILED: 'We couldn\'t sign you up. Please, try again.',
	USER_PASSWORD_TOO_SHORT: 'You password must contain at least 6 characters.',
	USER_SIGNIN_FAILED: 'We didn\'t recognize that password.',
	USER_RECOVER_PASSWORD_FAILED: 'We didn\'t recognize that email address. Want to try another?',
	USER_RESET_PASSWORD_FAILED: 'We couldn\'t save your new password. Please, try again.',
	USER_RESET_PASSWORD_EXPIRED: 'Sorry, your password reset link has expired.',
	USER_RESET_PASSWORD_MISMATCH: 'Your passwords must match.',

	// General
	FAILED_FETCH: 'We couldn\'t fetch your data. Please, try again.',
	FAILED_SAVE: 'We couldn\'t save your data. Please, try again.',
	FAILED_UPDATE: 'We couldn\'t update your data. Please, try again.',
	DELETE_FAILED: 'Something went wrong, please try again!',
	ERROR_POPUP_TITLE: 'Oops...',

	// Conference
	FAILED_ADD_TO_CALENDAR: 'Sorry, you need to set up a Deadline or a Presentation Date for this conference first.'
};

export const successMessage = {
	// Auth
	USER_RECOVER_PASSWORD_SUCCESS: 'Check your email for password reset instructions.',

	// General
	SUCCESS_POPUP_TITLE: 'Success!',

	// Article
	RELATED_WORK_DELETED_SUCCESS: 'Your related work has been deleted.',

	// Research Project
	PROJECT_DELETED_SUCCESS: 'Your project has been deleted.',

	// Article
	ARTICLE_DELETED_SUCCESS: 'Your article has been deleted.',

	// Conference
	CONFERENCE_DELETED_SUCCESS: 'Your conference has been deleted',

	// Experiment
	EXPERIMENT_DELETED_SUCCESS: 'Your experiment has been deleted'
};

export const formValidation = {
	NAME_REQUIRED: 'Name field cannot be empty.'
};

export const entityType = {
	ARTICLE: 'article',
	RELATED_WORK: 'relatedWork',
	EXPERIMENT: 'experiment',
	CONFERENCE: 'conference'
};

export const fileSource = {
	FILE_SYSTEM: 'file_system',
	GOOGLE: 'google',
	DROPBOX: 'dropbox',
	OVERLEAF: 'overleaf',
	OHTER: 'other'
};

export const fileValidation = {
	// 23 MB
	MAX_FILE_SIZE: 23551185
};

export const experimentStatus = {
	TO_START: 'TO_START',
	IN_PROGRESS: 'IN_PROGRESS',
	DONE: 'DONE'
};

export const conferenceFeeStatus = {
	NOT_PAID: 'NOT_PAID',
	PAID: 'PAID',
	PENDING_REINBURSEMENT: 'PENDING_REINBURSEMENT',
	REIMBURSED: 'REIMBURSED'
};

export const conferencePaperSubmissionStatus = {
	YES: 'YES',
	NO: 'NO'
};

export const stripePaymentLink = 'https://buy.stripe.com/bIY14peC01Dgbvy7ss?prefilled_email=%user_email%&utm_source=app.researchscale.io';
export const stripeCustomerPortalLink = 'https://billing.stripe.com/p/login/fZecNE8pKeCue88eUV';
