<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <div v-if="fetchDataFail" class="notification is-danger is-light has-text-centered">
            {{ errorMessage }}
        </div>

        <h2 class="title is-3">Articles</h2>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="goToLabPage"><font-awesome-icon icon="arrow-left" class="icon-button"/> {{ researchProject.name }}</a></li>
            <li class="is-active"><a href="#" aria-current="page">Articles</a></li>
          </ul>
        </nav>
        <hr />

        <div @click="toggleNewDropdown" class="dropdown" :class="{ 'is-active': isNewDropdownActive }">
          <div class="dropdown-trigger">
            <button class="button is-primary is-light" aria-haspopup="true" aria-controls="dropdown-menu2">
              <span>
                <font-awesome-icon icon="plus" class="icon-button"/> New...
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu2" role="menu">
            <div class="dropdown-content">
              <a @click="goToNewArticlePage('file_system')" class="dropdown-item">
                <p><font-awesome-icon icon="upload" class="icon-button"/>Upload file</p>
              </a>
              <hr class="dropdown-divider">
              <div class="dropdown-item">
                <p>Or link to files from...</p>
              </div>
              <a @click="goToNewArticlePage('google')" class="dropdown-item">
                <font-awesome-icon :icon="['fab', 'google-drive']" /> Google Drive
              </a>
              <a @click="goToNewArticlePage('dropbox')" class="dropdown-item">
                <font-awesome-icon :icon="['fab', 'dropbox']" /> Dropbox
              </a>
              <a @click="goToNewArticlePage('overleaf')" class="dropdown-item">
                <font-awesome-icon icon="leaf" class="icon-button"/> Overleaf
              </a>
              <a @click="goToNewArticlePage('other')" class="dropdown-item">
                <font-awesome-icon icon="external-link-alt" class="icon-button"/> Other
              </a>
            </div>
          </div>
        </div>
      </section>

      <div v-if="allArticles.length === 0">
        <section class="section message">
          <p class="message-title">This is a place to manage article docs.</p>
          <article class="message is-warning">
            <div class="message-header">
              <p>When would I use this?</p>
            </div>
            <div class="message-body">
              <p class="message-bullet">To keep all article docs in one place.</p>
              <p class="message-bullet">To manage different versions of your article files: initial propostal, partial report, final doc, etc.</p>
              <p class="message-bullet">Share article links with a teammate or professor.</p>
            </div>
          </article>
        </section>
      </div>

      <section v-if="allArticles.length !== 0" class="section items">
        <div class="columns header">
          <div class="column is-6">Name</div>
          <div class="column is-2">Last updated on</div>
          <div class="column is-2"></div>
          <div class="column is-2"></div>
        </div>

        <div
            v-for="article in allArticles"
            :key="article.id"
            class="items-grid">
          <div class="columns items">
            <div class="column is-6">
              {{ article.name}}
            </div>
            <div class="column is-2 date">
              {{ article.updated_at | formatDate }}
            </div>
            <div class="column is-2">
              <button class="button is-light" @click="openArticleLink(article.link)">
              <font-awesome-icon class="icon-button" v-if="article.source == 'overleaf'" icon="leaf" />
              <font-awesome-icon class="icon-button" v-if="article.source == 'file_system'" icon="file" />
              <font-awesome-icon class="icon-button" v-if="article.source == 'dropbox'" :icon="['fab', 'dropbox']" />
              <font-awesome-icon class="icon-button" v-if="article.source == 'google'" :icon="['fab', 'google-drive']" />
                Open File
              </button>
            </div>
            <div class="column is-2">
              <div @click="toggleOptionsDropdown(article.id)" class="dropdown" :class="{ 'is-active': article.selected }">
                <div class="dropdown-trigger">
                  <button class="button is-white is-light" aria-haspopup="true" aria-controls="dropdown-menu2">
                    <span>
                      <font-awesome-icon icon="ellipsis-h" class="icon-button"/>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content">
                    <a @click="editArticle(article.id, article.source)" class="dropdown-item">
                      <p><font-awesome-icon icon="edit" class="icon-button"/>Edit</p>
                    </a>
                    <a @click="deleteArticle(article.id)" class="dropdown-item">
                      <p><font-awesome-icon icon="trash" class="icon-button"/>Delete</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import router from '../../routes/router';
import {
	errorMessage,
	successMessage
} from '../../commons/constants';

export default {
	name: 'ArticleList',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			fetchDataFail: false,
			loading: false,
			isNewDropdownActive: false,
			isOptionsDropdownActive: false,
			errorMessage: errorMessage.FAILED_FETCH,
			researchProject: {},
			articles: []
		};
	},
	computed: {
		allArticles() {
			return this.articles;
		}
	},
	async created() {
		this.$forceUpdate();

		this.loading = true;
		await this.getResearchProject();
		await this.getArticles();
		this.loading = false;
	},
	methods: {
		async getResearchProject() {
			const { ok, data } = await api.researchProjects.getById(this.$route.params.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.researchProject = data;
		},
		async getArticles() {
			const { ok, data } = await api.articles.getAllByResearchProjectId(this.researchProject.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.articles = data;
		},
		toggleNewDropdown() {
			this.isNewDropdownActive = !this.isNewDropdownActive;
		},
		toggleOptionsDropdown(id) {
			const indexOfItem = this.articles.indexOf(this.articles.filter((x) => (x.id === id))[0]);
			this.$set(this.articles[indexOfItem], 'selected', !this.articles[indexOfItem].selected);
		},
		goToNewArticlePage(source) {
			router.push({ name: 'ArticlesDetails', params: { source: source, researchProjectId: this.researchProject.id } });
		},
		editArticle(id, source) {
			router.push({ name: 'ArticlesUpdate', params: { source: source, id: id, researchProjectId: this.researchProject.id } });
		},
		openArticleLink(link) {
			window.open(link);
		},
		goToLabPage() {
			router.push({ path: `/lab/${this.researchProject.id}` });
		},
		async deleteArticle(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You won\'t be able to revert this!',
				showCancelButton: true,
				confirmButtonColor: '#552fbc',
				cancelButtonColor: '#7957d5',
				confirmButtonText: 'Yes, delete it!'
			}).then(async(result) => {
				if (result.isConfirmed) {
					const { ok } = await api.articles.deleteArticle(id);

					if (!ok) {
						Swal.fire(
							errorMessage.ERROR_POPUP_TITLE,
							errorMessage.DELETE_FAILED,
							'error'
						);
						return;
					}

					Swal.fire(
						successMessage.SUCCESS_POPUP_TITLE,
						successMessage.ARTICLE_DELETED_SUCCESS,
						'success'
					);

					await this.getArticles();
				}
			});
		}
	}
};
</script>

<style scoped>
.icon-button {
  margin-right: 5px;
}
.section.message {
  background-color: unset;
  margin-top: -4rem;
}
.message-title {
  margin-bottom: 2rem;
}
.message-bullet {
  margin-bottom: 1.3rem;
}
.section.items {
  margin-top: -4rem;
}
.header {
  font-size: 1.2rem;
}
.items {
  align-items: center;
}
.items-grid .link {
  vertical-align: top;
}
</style>
