import axios from './axios-constructor';

export async function get(endpoint) {
	try {
		const authToken = localStorage.getItem('token');
		const res = await axios.get(`/${endpoint}`, {
			headers:
      { authorization: `Bearer ${authToken}` }
		});

		return {
			ok: res.status === 200,
			statusCode: res.status,
			data: res.data
		};
	} catch (error) {
		return {
			ok: false,
			statusCode: error.response ? error.response.status : 500,
			data: error
		};
	}
};

export async function post(endpoint, body) {
	try {
		const authToken = localStorage.getItem('token');
		const res = await axios.post(`/${endpoint}`, body, {
			headers:
      { authorization: `Bearer ${authToken}` }
		});

		return {
			ok: res.status === 200,
			statusCode: res.status,
			data: res.data
		};
	} catch (error) {
		return {
			ok: false,
			statusCode: error.response ? error.response.status : 500,
			data: error
		};
	}
};

export async function put(endpoint, body) {
	try {
		const authToken = localStorage.getItem('token');
		const res = await axios.put(`/${endpoint}`, body, {
			headers:
      { authorization: `Bearer ${authToken}` }
		});

		return {
			ok: res.status === 200,
			statusCode: res.status,
			data: res.data
		};
	} catch (error) {
		return {
			ok: false,
			statusCode: error.response ? error.response.status : 500,
			data: error
		};
	}
};

export async function deleteEndpoint(endpoint) {
	try {
		const authToken = localStorage.getItem('token');
		const res = await axios.delete(`/${endpoint}`, {
			headers:
      { authorization: `Bearer ${authToken}` }
		});

		return {
			ok: res.status === 200,
			statusCode: res.status,
			data: res.data
		};
	} catch (error) {
		return {
			ok: false,
			statusCode: error.response ? error.response.status : 500,
			data: error
		};
	}
};
