import * as base from '../base';

export async function getAllByResearchProjectId(id) {
	return await base.get(`related-works?researchProjectId=${id}`);
};

export async function getById(id) {
	return await base.get(`related-works/${id}`);
};

export async function save(relatedWork) {
	return await base.post('related-works', relatedWork);
};

export async function update(relatedWork) {
	return await base.put(`related-works/${relatedWork.id}`, relatedWork);
};

export async function deleteRelatedWork(id) {
	return await base.deleteEndpoint(`related-works/${id}`);
};
