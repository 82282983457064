import Vue from 'vue';
import { formatDateToDisplay, formatDateToDisplayNotification } from '../commons/formatDate';

Vue.filter('formatDate', (value) => {
	if (!value) return '';
	return formatDateToDisplay(value);
});

Vue.filter('formatDateForNotification', (value) => {
	if (!value) return '';
	return formatDateToDisplayNotification(value);
});
