<template>
  <section class="container">

    <div class="columns is-multiline">
      <div v-if="!isTokenValid" class="column is-one-third is-offset-4 register">
        <div class="notification is-danger is-light has-text-centered">
            {{ message }}
        </div>
      </div>
      <div v-else-if="isTokenValid" class="column is-one-third is-offset-4 register">
        <div v-if="resetPasswordFailed || !passwordsMatch" class="notification is-danger is-light has-text-centered">
            {{ message }}
        </div>
        <div v-if="resetPasswordSuccess" class="notification is-success is-light has-text-centered">
            {{ message }}
        </div>
        <div class="columns">
          <div class="column right has-text-centered">
            <h1 class="title is-3">Reset your password</h1>
            <form @submit.prevent="onSubmit">
              <div class="field">
                <div class="control">
                  <input
                    v-model="password"
                    class="input is-medium"
                    type="password"
                    required="true"
                    placeholder="Enter a new password"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    v-model="confirmPassword"
                    class="input is-medium"
                    type="password"
                    required="true"
                    placeholder="Confirm your password"
                  />
                </div>
              </div>
              <button class="button is-block is-primary is-fullwidth is-medium" type="submit">Save my password</button>
              <br />
            </form>
          </div>
        </div>
      </div>
      <div class="column is-one-third is-offset-4">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <span class="icon">
                <i class="fab fa-twitter"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fab fa-github"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="level-right">
            <small @click="goToLandingPage" class="level-item" style="color: var(--textLight); cursor: pointer">
              &copy; ResearchScale. All Rights Reserved.
            </small>
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>
<script>
import router from '../../routes/router';
import api from '../../api';

import {
	errorMessage
} from '../../commons/constants';

export default {
	data() {
		return {
			password: '',
			confirmPassword: '',
			message: '',
			user: {},
			resetPasswordFailed: false,
			resetPasswordSuccess: false,
			isTokenValid: false,
			passwordsMatch: true
		};
	},
	async created() {
		const resetToken = this.$router.currentRoute.query.resetPasswordToken;
		if (!resetToken) {
			this.isTokenValid = false;
			this.message = errorMessage.USER_RESET_PASSWORD_EXPIRED;
			return;
		}

		const { ok, data } = await api.user.checkResetPassword(resetToken);
		if (!ok) {
			this.isTokenValid = false;
			this.message = errorMessage.USER_RESET_PASSWORD_EXPIRED;
			return;
		}

		this.isTokenValid = true;
		this.user = data;
	},
	methods: {
		async onSubmit() {
			if (!this.validatePasswords()) {
				return;
			};

			const resetPasswordResponse = await api.user.resetPassword({
				userId: this.user.id,
				password: this.password
			});

			if (!resetPasswordResponse.ok) {
				this.resetPasswordFailed = true;
				this.message = errorMessage.USER_RESET_PASSWORD_FAILED;
				return;
			}

			this.resetPasswordFailed = false;
			this.resetPasswordSuccess = true;

			this.$store.dispatch('signin', resetPasswordResponse.data);
			this.$store.dispatch('storeUser', this.user);

			router.push({ path: '/' });
		},
		validatePasswords() {
			if (this.password !== this.confirmPassword) {
				this.passwordsMatch = false;
				this.message = errorMessage.USER_RESET_PASSWORD_MISMATCH;
				return false;
			}

			if (this.password.length < 6) {
				this.passwordsMatch = false;
				this.message = errorMessage.USER_PASSWORD_TOO_SHORT;
				return false;
			}

			this.passwordsMatch = true;
			return true;
		},
		goToLandingPage() {
			window.open('https://researchscale.io');
		}
	}
};
</script>
<style scoped>
.field:not(:last-child) {
  margin-bottom: 1rem;
}

.register {
  margin-top: 10rem;
  background: white;
  border-radius: 10px;
}

.left,
.right {
  padding: 4.5rem;
}

.left {
  border-right: 5px solid var(--background);
}

.left .title {
  font-weight: 800;
  letter-spacing: -2px;
}

.left .colored {
  color: var(--brandColor);
  font-weight: 500;
  margin-top: 1rem !important;
  letter-spacing: -1px;
}

.left p {
  color: var(--textLight);
  font-size: 1.15rem;
}

.right .title {
  font-weight: 800;
  letter-spacing: -1px;
}

.right .description {
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  color: var(--textLight);
  font-size: 1rem;
}

.right small {
  color: var(--textLight);
}

input {
  font-size: 1rem;
}

input:focus {
  border-color: var(--brandColor) !important;
  box-shadow: 0 0 0 1px var(--brandColor) !important;
}

.fab,
.fas {
  color: var(--textLight);
  margin-right: 1rem;
}
</style>
