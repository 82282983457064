<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <div v-if="fetchDataFail" class="notification is-danger is-light has-text-centered">
            {{ errorMessage }}
        </div>

        <h2 class="title is-3">Research projects</h2>
        <hr />

        <button
          @click="goToNewProjectPage"
          class="button is-primary is-light"
        >
          <font-awesome-icon icon="plus" class="icon-button"/> New
        </button>
      </section>

      <section class="section items">
        <div v-if="researchProjects.length > 0" class="columns is-multiline">
          <div
            v-for="project in researchProjects"
            :key="project.id"
            class="column is-half"
          >
            <div class="card">
              <div class="card-content" @click="openProject(project.id)">
                <p class="title">
					{{ project.name }}
					<span
						v-if="project.isSample"
						class="tag is-info is-light is-small sample-tag">
							SAMPLE
					</span>
                </p>
                <p class="subtitle">
                  {{ project.description }}
                </p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item">
                  <span>
                    <button @click="updateProject(project.id)" class="button is-ghost">
                      Update
                    </button>
                  </span>
                </p>
                <p class="card-footer-item">
                  <span>
                    <button @click="deleteProject(project.id)" class="button is-ghost">
                      Delete
                    </button>
                  </span>
                </p>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import humps from 'humps';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import router from '../../routes/router';
import {
	errorMessage,
	successMessage
} from '../../commons/constants';

export default {
	name: 'HelloWorld',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			fetchDataFail: false,
			loading: false,
			errorMessage: errorMessage.FAILED_FETCH,
			researchProjects: []
		};
	},
	async created() {
		this.$forceUpdate();

		this.loading = true;
		await this.getProjects();
		this.loading = false;
	},
	methods: {
		async getProjects() {
			const { ok, data } = await api.researchProjects.getAll();
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.researchProjects = humps.camelizeKeys(data);
		},
		goToNewProjectPage() {
			router.push({ path: '/research-project' });
		},
		openProject(id) {
			router.push({ path: `/lab/${id}` });
		},
		updateProject(id) {
			router.push({ path: `/research-project/${id}` });
		},
		async deleteProject(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You won\'t be able to revert this!',
				showCancelButton: true,
				confirmButtonColor: '#552fbc',
				cancelButtonColor: '#7957d5',
				confirmButtonText: 'Yes, delete it!'
			}).then(async(result) => {
				if (result.isConfirmed) {
					const { ok } = await api.researchProjects.deleteProject(id);

					if (!ok) {
						Swal.fire(
							errorMessage.ERROR_POPUP_TITLE,
							errorMessage.DELETE_FAILED,
							'error'
						);
						return;
					}

					Swal.fire(
						successMessage.SUCCESS_POPUP_TITLE,
						successMessage.PROJECT_DELETED_SUCCESS,
						'success'
					);

					await this.getProjects();
				}
			});
		}
	}
};
</script>

<style scoped>
.icon-button {
  margin-right: 5px;
}
.section.items {
  margin-top: -4rem;
}
.card {
  height: 100%;
  width: 100%;
}
.card-content {
  cursor: pointer;
  /* height: 75%; */
}
.card-footer {
  height: 50px;
}
.card .title {
  font-size: 1.2rem;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .subtitle {
  font-size: 0.9rem;
  min-height: 55px;
  max-height: 55px;

  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sample-tag {
	float: right;
}
</style>
