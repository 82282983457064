<template>
	<div>
		<loading-spinner :isLoading="loading" />

		<section v-if="!loading" class="container">
			<section class="section">
				<h2 class="title is-3">Search Assistant</h2>
				<hr />
			</section>

			<section class="section form">
				<div class="columns">
					<div class="column is-two-thirds">
						<article v-if="showChromeWarning" class="message is-warning">
							<div class="message-header">
								<p>Only works with <b>Google Chrome</b></p>
								<button @click="toggleChromeWarning" class="delete" aria-label="delete"></button>
							</div>
							<div class="message-body">
								1. If you are using other browsers
								<strong>
									<a href="https://www.google.com/chrome/" target="_blank">
										download Chrome.
									</a>
								</strong>
								<br/><br/>
								2. Make sure you have pop-ups allowed in your browser settings.
								<strong>
									<a href="https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop" target="_blank">
										How to allow pop-ups?
									</a>
								</strong>
							</div>
						</article>
						<div class="field">
							<label class="label">Search term</label>
							<div class="control">
								<input
									v-model="searchTerm"
									class="input"
									type="text"
									placeholder='Keyword, title or any search term e.g. "Software Defined Networks"'
								/>
							</div>
							<p
								v-if="isFormInvalid"
								class="help is-danger"
							>
								Keyword cannot be empty
							</p>
						</div>

						<div class="block">
							<font-awesome-icon icon="info-circle" class="icon-button"/> We'll search on all sources checked below:
							<br/><br/>

							<div class="columns">
								<div class="column">
									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="arxiv" v-model="checkedSearchOptions">
												ArXiv
										</label>
									</div>

									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="acm" v-model="checkedSearchOptions">
												ACM DIgital Libray
										</label>
									</div>

									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="ieee" v-model="checkedSearchOptions">
												IEEE Xplore
										</label>
									</div>
								</div>

								<div class="column">
									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="springer" v-model="checkedSearchOptions">
												Springer
										</label>
									</div>

									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="usenix" v-model="checkedSearchOptions">
												USENIX
										</label>
									</div>

									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="sbc" v-model="checkedSearchOptions">
												SBC (brazilian computer society)
										</label>
									</div>
								</div>

								<div class="column">
									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="wikipedia" v-model="checkedSearchOptions">
												Wikipedia
										</label>
									</div>

									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="researchGate" v-model="checkedSearchOptions">
												ResearchGate
										</label>
									</div>

									<div class="field">
										<label class="checkbox">
											<input type="checkbox" value="scholar" v-model="checkedSearchOptions">
												Google Scholar
										</label>
									</div>
								</div>
							</div>
						</div>

						<div class="field is-grouped">
							<div class="control">
								<button
									@click="search"
									class="button is-link"
								>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	</div>
</template>

<script>
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import { errorMessage } from '../../commons/constants';

export default {
	name: 'SearchAssistantDetails',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			loading: false,
			isFormInvalid: false,
			errorMessage: errorMessage.FAILED_SAVE,
			showChromeWarning: true,
			searchTerm: '',
			checkedSearchOptions: [
				'arxiv',
				'acm',
				'ieee',
				'springer',
				'usenix',
				'sbc',
				'wikipedia',
				'researchGate',
				'scholar'
			]
		};
	},
	async created() {
	},
	methods: {
		validateForm() {
			this.isFormInvalid = false;

			if (!this.searchTerm || this.searchTerm.length === 0 || this.searchTerm === ' ') {
				this.isFormInvalid = true;
			}

			return !this.isFormInvalid;
		},
		toggleChromeWarning() {
			this.showChromeWarning = false;
		},
		search() {
			if (!this.validateForm()) {
				return;
			}

			const arxivURL = `https://arxiv.org/search/?query=${this.searchTerm}&searchtype=all&abstracts=show&order=-announced_date_first&size=50`;
			const sbcURL = `https://sol.sbc.org.br/busca/index.php/integrada/results?isAdvanced=1&archiveIds[]=1&archiveIds[]=2&archiveIds[]=3&query=${this.searchTerm}&field-3=&field-15=&field-4=&field-14=&field-16=&field-7-fromMonth=&field-7-fromDay=&field-7-fromYear=&field-7-toMonth=&field-7-toDay=&field-7-toYear=`;
			const acmURL = `https://dl.acm.org/action/doSearch?AllField=${this.searchTerm}`;
			const usenixURL = `https://www.usenix.org/publications/proceedings/${this.searchTerm}`;
			const scholarURL = `https://scholar.google.com/scholar?as_sdt=0%2C5&q=${this.searchTerm}&btnG=`;
			const researchGateURL = `https://www.researchgate.net/search/publication?q=${this.searchTerm}`;
			const ieeeURL = `https://ieeexplore.ieee.org/search/searchresult.jsp?newsearch=true&contentType=conferences&queryText=${this.searchTerm}`;
			const wikipediaURL = `https://en.wikipedia.org/w/index.php?search=${this.searchTerm}&title=Special%3ASearch&ns0=1`;
			const springerURL = `https://link.springer.com/search?query=${this.searchTerm}`;

			const dictSearchOptions = {
				researchGate: researchGateURL,
				arxiv: arxivURL,
				acm: acmURL,
				ieee: ieeeURL,
				springer: springerURL,
				usenix: usenixURL,
				sbc: sbcURL,
				wikipedia: wikipediaURL,
				scholar: scholarURL
			};

			for (const [index, searchOption] of this.checkedSearchOptions.entries()) {
				window.open(dictSearchOptions[searchOption], `window${index}`);
			}
		}
	}
};
</script>

<style scoped>
.form {
  margin-top: -4rem;
}
</style>
