import * as base from '../base';

export async function getAll() {
	return await base.get('research-projects');
};

export async function getById(id) {
	return await base.get(`research-projects/${id}`);
};

export async function save(project) {
	return await base.post('research-projects', project);
};

export async function update(project) {
	return await base.put('research-projects', project);
};

export async function deleteProject(id) {
	return await base.deleteEndpoint(`research-projects/${id}`);
};
