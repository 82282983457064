import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: null,
		userId: null,
		user: null
	},
	mutations: {
		authUser(state, userData) {
			state.token = userData.token;
			state.userId = userData.userId;
			state.user = userData.user;
		},
		storeUser(state, user) {
			state.user = user;
		},
		clearAuthData(state) {
			state.token = null;
			state.userId = null;
			state.user = null;
		}
	},
	actions: {
		async signup({ commit, dispatch }, authData) {
			const now = new Date();
			const expirationDate = new Date(now.getTime() + authData.expiresIn * 1000);

			localStorage.setItem('token', authData.token);
			localStorage.setItem('userId', authData.userId);
			localStorage.setItem('expirationDate', expirationDate);

			commit('authUser', {
				token: authData.token,
				userId: authData.userId
			});
			dispatch('setLogoutTimer', authData.expiresIn);
		},
		async signin({ commit, dispatch }, authData) {
			const now = new Date();
			const expirationDate = new Date(now.getTime() + authData.expiresIn * 1000);

			localStorage.setItem('token', authData.token);
			localStorage.setItem('userId', authData.userId);
			localStorage.setItem('expirationDate', expirationDate);

			commit('authUser', {
				token: authData.token,
				userId: authData.userId
			});
			dispatch('setLogoutTimer', authData.expiresIn);
		},
		tryAutoLogin({ commit }) {
			const token = localStorage.getItem('token');
			if (!token) {
				return;
			}

			const expirationDate = localStorage.getItem('expirationDate');
			const now = new Date();
			if (now >= expirationDate) {
				return;
			}

			const userId = localStorage.getItem('userId');
			const user = JSON.parse(localStorage.getItem('user'));
			commit('authUser', {
				token: token,
				userId: userId,
				user: user
			});
		},
		setLogoutTimer({ commit }, expirationTime) {
			setTimeout(() => {
				commit('clearAuthData');
			}, expirationTime * 1000);
		},
		logout({ commit }) {
			commit('clearAuthData');

			localStorage.removeItem('expirationDate');
			localStorage.removeItem('token');
			localStorage.removeItem('userId');
			localStorage.removeItem('user');
		},
		storeUser({ commit }, userData) {
			localStorage.setItem('user', JSON.stringify(userData));
			commit('storeUser', userData);
		}
	},
	getters: {
		user(state) {
			return state.user;
		},
		isAuthenticated(state) {
			return state.token !== null;
		}
	}
});
