import Vue from 'vue';
import App from './App.vue';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import './filters/filters';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faEye,
	faCopy,
	faCalendar,
	faComment,
	faDollarSign,
	faDownload,
	faEllipsisH,
	faEdit,
	faChartLine,
	faTrash,
	faPlus,
	faPlay,
	faArrowCircleLeft,
	faLeaf,
	faFile,
	faUpload,
	faVideo,
	faLink,
	faCheck,
	faExternalLinkAlt,
	faExclamationCircle,
	faArrowLeft,
	faArrowRight,
	faLongArrowAltRight,
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import { faDropbox, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';

import router from './routes/router';
import store from './store/store';

library.add(
	faEye,
	faCopy,
	faCalendar,
	faComment,
	faDollarSign,
	faExternalLinkAlt,
	faCheck,
	faDownload,
	faEllipsisH,
	faEdit,
	faExclamationCircle,
	faLeaf,
	faChartLine,
	faTrash,
	faInfoCircle,
	faPlus,
	faPlay,
	faArrowCircleLeft,
	faArrowLeft,
	faArrowRight,
	faLongArrowAltRight,
	faFile,
	faUpload,
	faVideo,
	faLink,
	faDropbox,
	faGoogleDrive
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(Buefy);

new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app');
