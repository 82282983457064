<template>
  <div id="app">
    <link rel="stylesheet" href="@sweetalert2/theme-bulma/bulma.css">
    <app-header />
    <router-view></router-view>
  </div>
</template>

<style>
:root {
  --brandColor: #2ed6af;
  /* --brandColor: #F05454; */
  /* --background: rgb(247, 247, 247); */
  /* --background: var(--background); */
  --textDark: hsla(0, 0%, 0%, 0.66);
  --textLight: hsla(0, 0%, 0%, 0.33);
  --link: #167df0;
}

body {
  background: var(--background);
  --background: rgb(250, 250, 250);
  height: 140vh; /* todo: update this to fix scroll behavior */
  color: var(--textDark);
}
</style>

<script>
import Header from './components/header/Header.vue';

export default {
	name: 'App',
	components: {
		'app-header': Header
	},
	created() {
		this.$store.dispatch('tryAutoLogin');
	}
};
</script>
