<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <div v-if="fetchDataFail" class="notification is-danger is-light has-text-centered">
            {{ errorMessage }}
        </div>

        <h2 class="title is-3">Experiments</h2>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="goToLabPage"><font-awesome-icon icon="arrow-left" class="icon-button"/> {{ researchProject.name }}</a></li>
            <li class="is-active"><a href="#" aria-current="page">Experiments</a></li>
          </ul>
        </nav>
        <hr />

        <button
          @click="goToNewExperimentPage"
          class="button is-primary is-light"
        >
          <font-awesome-icon icon="plus" class="icon-button"/> New
        </button>
      </section>

      <div v-if="allExperiments.length === 0">
        <section class="section message">
          <p class="message-title">This is a place to manage experiments.</p>
          <article class="message is-warning">
            <div class="message-header">
              <p>When would I use this?</p>
            </div>
            <div class="message-body">
              <p class="message-bullet">To plan all your experiments in a single place.</p>
              <p class="message-bullet">Track experiment results and manage your scripts.</p>
              <p class="message-bullet">Keep your benchmarking results.</p>
            </div>
          </article>
        </section>
      </div>

      <section v-if="allExperiments.length !== 0" class="section items">
        <div class="columns header">
          <div class="column is-4">Name</div>
          <div class="column is-2">Status</div>
          <div class="column is-2">Started on</div>
          <div class="column is-2">Finished on</div>
          <div class="column is-2"></div>
        </div>

        <div
            v-for="experiment in allExperiments"
            :key="experiment.id"
            class="items-grid">
          <div class="columns items">
            <div class="column is-4">
              {{ experiment.name }}
            </div>
            <div class="column is-2">
              <span
                v-if="experiment.status === 'TO_START'"
                class="tag is-info"
              >
                To start
              </span>
              <span
                v-if="experiment.status === 'IN_PROGRESS'"
                class="tag is-warning"
              >
                In progress
              </span>
              <span
                v-if="experiment.status === 'DONE'"
                class="tag is-success"
              >
                Done
              </span>
            </div>
            <div class="column is-2 date">
              {{ experiment.startedAt | formatDate }}
            </div>
            <div class="column is-2 date">
              {{ experiment.finishedAt | formatDate }}
            </div>
            <div class="column is-2">
              <div @click="toggleOptionsDropdown(experiment.id)" class="dropdown" :class="{ 'is-active': experiment.selected }">
                <div class="dropdown-trigger">
                  <button class="button is-white is-light" aria-haspopup="true" aria-controls="dropdown-menu2">
                    <span>
                      <font-awesome-icon icon="ellipsis-h" class="icon-button"/>
                    </span>
                  </button>
                </div>

				<!-- options menu -->
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
					<div class="dropdown-content">
						<!-- experiment actions -->
						<div v-if="!isExperimentDone(experiment.status)">
							<div v-if="isExperimentToStart(experiment.status)">
								<a
									@click="startExperiment(experiment)"
									class="dropdown-item"
								>
									<p><font-awesome-icon icon="play" class="icon-button"/>Start Experiment</p>
								</a>
							</div>

							<div v-if="isExperimentInProgress(experiment.status)">
								<a
									@click="finishExperiment(experiment)"
									class="dropdown-item"
								>
									<p><font-awesome-icon icon="check" class="icon-button"/>Post results</p>
								</a>
							</div>
							<hr class="dropdown-divider" />
						</div>

						<!-- script files and links -->
						<div v-if="experiment.scriptExternalLink || experiment.scriptFileLink">
							<div v-if="experiment.scriptExternalLink">
								<a
									@click="openScript(experiment.scriptExternalLink)"
									class="dropdown-item"
								>
									<p><font-awesome-icon icon="external-link-alt" class="icon-button"/>Open script link</p>
								</a>
							</div>

							<div v-if="experiment.scriptFileLink">
								<a
									@click="downloadScript(experiment.scriptFileLink)"
									class="dropdown-item"
								>
									<p><font-awesome-icon icon="download" class="icon-button"/>Download script file</p>
								</a>
							</div>
							<hr class="dropdown-divider" />
						</div>

						<!-- result data -->
						<div v-if="experiment.resultStatus">
							<a
								@click="viewResults(experiment.id)"
								class="dropdown-item"
							>
								<p><font-awesome-icon icon="eye" class="icon-button"/>See results</p>
							</a>

							<div v-if="experiment.resultFileLink">
								<a
									@click="downloadResults(experiment.resultFileLink)"
									class="dropdown-item"
								>
									<p><font-awesome-icon icon="download" class="icon-button"/>Download results file</p>
								</a>
							</div>

							<hr class="dropdown-divider" />
						</div>

						<a @click="editExperiment(experiment.id)" class="dropdown-item">
							<p><font-awesome-icon icon="edit" class="icon-button"/>Edit</p>
						</a>
						<a @click="deleteExperiment(experiment.id)" class="dropdown-item">
							<p><font-awesome-icon icon="trash" class="icon-button"/>Delete</p>
						</a>
					</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import humps from 'humps';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import router from '../../routes/router';
import {
	errorMessage,
	experimentStatus,
	successMessage
} from '../../commons/constants';

export default {
	name: 'ExperimentsList',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			fetchDataFail: false,
			loading: false,
			isOptionsDropdownActive: false,
			errorMessage: errorMessage.FAILED_FETCH,
			researchProject: {},
			experiments: []
		};
	},
	computed: {
		allExperiments() {
			return this.experiments;
		}
	},
	async created() {
		this.$forceUpdate();

		this.loading = true;
		await this.getResearchProject();
		await this.getExperiments();
		this.loading = false;
	},
	methods: {
		async getResearchProject() {
			const { ok, data } = await api.researchProjects.getById(this.$route.params.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.researchProject = data;
		},
		async getExperiments() {
			const { ok, data } = await api.experiments.getAllByResearchProjectId(this.researchProject.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.experiments = humps.camelizeKeys(data);
		},
		toggleNewDropdown() {
			this.isNewDropdownActive = !this.isNewDropdownActive;
		},
		toggleOptionsDropdown(id) {
			const indexOfItem = this.experiments.indexOf(this.experiments.filter((x) => (x.id === id))[0]);
			this.$set(this.experiments[indexOfItem], 'selected', !this.experiments[indexOfItem].selected);
		},
		openScript(link) {
			window.open(link);
		},
		downloadScript(link) {
			window.open(link);
		},
		openResults(id) {
			alert('Open results', id);
		},
		downloadResults(link) {
			window.open(link);
		},
		isExperimentToStart(status) {
			return status === experimentStatus.TO_START;
		},
		isExperimentInProgress(status) {
			return status === experimentStatus.IN_PROGRESS;
		},
		isExperimentDone(status) {
			return status === experimentStatus.DONE;
		},
		async startExperiment(experiment) {
			this.loading = true;
			const { ok } = await api.experiments.start(experiment);

			if (!ok) {
				Swal.fire(
					errorMessage.ERROR_POPUP_TITLE,
					errorMessage.FAILED_UPDATE,
					'error'
				);
				this.loading = false;
				return;
			}

			await this.getExperiments();
			this.loading = false;
		},
		async finishExperiment(experiment) {
			router.push({ name: 'ExperimentsResultDetails', params: { id: experiment.id } });
		},
		goToNewExperimentPage() {
			router.push({ name: 'ExperimentsDetails', params: { researchProjectId: this.researchProject.id } });
		},
		editExperiment(id) {
			router.push({ name: 'ExperimentsUpdate', params: { id: id, researchProjectId: this.researchProject.id } });
		},
		viewResults(id) {
			router.push({ name: 'ExperimentsResultView', params: { id: id } });
		},
		goToLabPage() {
			router.push({ path: `/lab/${this.researchProject.id}` });
		},
		async deleteExperiment(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You won\'t be able to revert this!',
				showCancelButton: true,
				confirmButtonColor: '#552fbc',
				cancelButtonColor: '#7957d5',
				confirmButtonText: 'Yes, delete it!'
			}).then(async(result) => {
				if (result.isConfirmed) {
					const { ok } = await api.experiments.deleteExperiment(id);

					if (!ok) {
						Swal.fire(
							errorMessage.ERROR_POPUP_TITLE,
							errorMessage.DELETE_FAILED,
							'error'
						);
						return;
					}

					Swal.fire(
						successMessage.SUCCESS_POPUP_TITLE,
						successMessage.EXPERIMENT_DELETED_SUCCESS,
						'success'
					);

					await this.getExperiments();
				}
			});
		}
	}
};
</script>

<style scoped>
.icon-button {
  margin-right: 5px;
}
.section.message {
  margin-top: -4rem;
  background-color: unset;
}
.message-title {
  margin-bottom: 2rem;
}
.message-bullet {
  margin-bottom: 1.3rem;
}
.section.items {
  margin-top: -4rem;
}
.header {
  font-size: 1.2rem;
}
.items {
  align-items: center;
}
.items-grid .link {
  vertical-align: top;
}
</style>
