<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
		<section class="section">
			<h2 class="title is-3">Experiment</h2>
			<hr />
		</section>

		<section class="section form">
			<div class="columns">
				<div class="column is-two-thirds">
					<div v-if="saveDataFail" class="notification is-danger is-light has-text-centered">
						{{ errorMessage }}
					</div>

					<div class="field">
						<label class="label">What do you want to call this experiment?</label>
						<div class="control">
							<input
							v-model="experiment.name"
							class="input"
							type="text"
							placeholder="Title of this experiment..."
							/>
						</div>
						<p
							v-if="isFormInvalid"
							class="help is-danger"
						>
							Title cannot be empty
						</p>
					</div>

					<div class="field">
						<label class="label">Description</label>
						<div class="control">
							<textarea
								v-model="experiment.description"
								class="textarea"
								placeholder="Brief description, or a note about the experiment..."
							></textarea>

							<p
								v-if="isFormInvalid"
								class="help is-danger"
							>
								Experiment description cannot be empty
							</p>
						</div>
					</div>

					<div class="field">
						<label class="label">Target date (optional)</label>
						<div class="control">
							<input
								v-model="experiment.targetDate"
								class="input"
								type="date"
								placeholder="Title of this experiment..."
							/>
						</div>
					</div>

					<!-- scripts and Files section -->
					<hr/>
					<h2 class="title is-4">Scripts and Files</h2>

					<div class="field">
						<div class="file is-fullwidth has-name">
							<label class="file-label">
								<input
									@change="uploadFile"
									class="file-input"
									type="file"
									id="file-input"
									ref="files"
								/>
								<span class="file-cta">
									<span class="file-icon">
										<font-awesome-icon class="icon-button" icon="upload" />
									</span>
									<span class="file-label">
									Upload a file…
									</span>
								</span>
								<span class="file-name">
									{{ fileName }}
								</span>
							</label>
						</div>

						<p
							v-if="isFileInvalid"
							class="help is-danger"
						>
							File size must not exceed 20 MB.
						</p>

						<p
							v-if="fileUploadFail"
							class="help is-danger"
						>
							Error uploading your file. Please try again.
						</p>

						<div class="field">
							<div class="label"></div>
							<div v-if="fileUploadStarted">
								<progress v-if="!fileUploadSuccess" class="progress is-success is-primary" max="100">15%</progress>
								<progress v-if="fileUploadSuccess" class="progress is-success" value="100" max="100">100%</progress>
								<progress v-if="fileUploadFail" class="progress is-danger" value="90" max="100">90%</progress>
							</div>
						</div>
					</div>

					<div class="field">
						<label class="label">File description (optional)</label>
						<div class="control">
							<textarea
								v-model="experiment.scriptFileDescription"
								class="textarea"
								placeholder="Brief description about what is the file about..."
							></textarea>
						</div>
					</div>

					<div class="field">
						<label class="label">External file, paste the link here (optional)</label>
						<div class="control">
							<p class="control has-icons-left">
								<input
									v-model="experiment.scriptExternalLink"
									class="input"
									type="text"
									placeholder="e.g. https://github.com/andreybleme/calisto/experiments"
								/>
								<span class="icon is-small is-left">
									<font-awesome-icon class="icon-button" icon="link" />
								</span>
							</p>
						</div>
					</div>

					<div class="field">
						<label class="label">External file description (optional)</label>
						<div class="control">
							<textarea
								v-model="experiment.scriptExternalLinkDescription"
								class="textarea"
								placeholder="Brief description about what is the link about..."
							></textarea>
						</div>
					</div>

					<!-- action buttons -->
					<div class="field is-grouped">
						<div class="control">
							<button
							@click="save"
							class="button is-link"
							>
							{{ this.$route.params.id ? 'Update this experiment' : 'Create this experiment' }}
							</button>
						</div>
						<div class="control">
							<button
							@click="goToExperimentListPage"
							class="button is-link is-light"
							>Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import humps from 'humps';
import moment from 'moment';

import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';

import {
	errorMessage,
	entityType,
	experimentStatus,
	fileValidation
} from '../../commons/constants';

import router from '../../routes/router';

export default {
	name: 'ExperimentsDetails',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			loading: false,
			saveDataFail: false,
			isFormInvalid: false,
			isFileInvalid: false,
			fileUploadFail: false,
			fileUploadStarted: false,
			fileUploadSuccess: false,
			fileNameUploaded: 'e.g. latency-test.py, perf.zip',
			errorMessage: errorMessage.FAILED_SAVE,
			experiment: {
				name: '',
				description: '',
				targetDate: '',
				status: experimentStatus.TO_START,
				scriptFileLink: '',
				scriptFileDescription: '',
				scriptExternalLink: '',
				scriptExternalLinkDescription: '',
				resultStatus: '',
				resultDescription: '',
				resultFileLink: '',
				researchProjectId: ''
			}
		};
	},
	computed: {
		fileName() {
			return this.fileNameUploaded;
		}
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadEntityData();
		}

		this.experiment.researchProjectId = this.$route.params.researchProjectId;
	},
	methods: {
		validateForm() {
			this.isFormInvalid = false;

			if (this.experiment.name === undefined || this.experiment.name.length === 0) {
				this.isFormInvalid = true;
			}

			if (this.experiment.description === undefined || this.experiment.description.length === 0) {
				this.isFormInvalid = true;
			}

			return !this.isFormInvalid;
		},
		async loadEntityData() {
			const { ok, data } = await api.experiments.getById(this.$route.params.id);
			if (ok && data) {
				this.experiment = humps.camelizeKeys(data);
				if (this.experiment.targetDate) {
					this.experiment.targetDate = moment(this.experiment.targetDate).format('YYYY-MM-DD');
				}

				/*
				* This is a workaround for extracting the file name from the S3 URL.
				* In the future: lets refactor the POST /experiment endpoint to save the original file name.
				* Then we could retrieve it from the GET /experiment/:id endpoint and remove this line.
				*/
				if (this.experiment.scriptFileLink) {
					this.fileNameUploaded = this.experiment.scriptFileLink.split('/')[3].substring(33, this.experiment.scriptFileLink.length);
				}
			}
		},
		async uploadFile() {
			this.fileUploadStarted = false;
			this.fileUploadSuccess = false;

			const fileData = this.$refs.files.files[0];
			this.fileNameUploaded = fileData.name;
			if (fileData.size > fileValidation.MAX_FILE_SIZE) {
				this.isFileInvalid = true;
				return;
			}

			this.fileUploadStarted = true;
			this.isFileInvalid = false;

			const formData = new FormData();
			formData.append('entityType', entityType.EXPERIMENT);
			formData.append('file', fileData);

			const { ok, data } = await api.files.upload(formData);
			if (!ok) {
				this.saveDataFail = true;
			}

			this.experiment.scriptFileLink = data.link;
			this.fileUploadSuccess = true;
		},
		async save() {
			if (!this.validateForm()) {
				return;
			}

			this.loading = true;
			if (!this.$route.params.id) {
				if (this.experiment.targetDate) {
					this.experiment.targetDate = new Date(this.experiment.targetDate).toISOString();
				}

				const { ok } = await api.experiments.save(this.experiment);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your experiment was created!',
					'success'
				);
			} else {
				const { ok } = await api.experiments.update(this.experiment);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your experiment was updated!',
					'success'
				);
			}

			this.loading = false;
			router.push({ path: `/experiments/${this.experiment.researchProjectId}` });
		},
		goToExperimentListPage() {
			router.push({ path: `/experiments/${this.experiment.researchProjectId}` });
		}
	}
};
</script>

<style scoped>
.form {
  margin-top: -4rem;
}
</style>
