<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <div v-if="fetchDataFail" class="notification is-danger is-light has-text-centered">
            {{ errorMessage }}
        </div>

        <h2 class="title is-3">Related Works</h2>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="goToLabPage"><font-awesome-icon icon="arrow-left" class="icon-button"/> {{ researchProject.name }}</a></li>
            <li class="is-active"><a href="#" aria-current="page">Related Works</a></li>
          </ul>
        </nav>
        <hr />

        <button
          @click="goToNewRelatedWorkPage"
          class="button is-primary is-light"
        >
          <font-awesome-icon icon="plus" class="icon-button"/> New
        </button>
      </section>

      <div v-if="allRelatedWorks.length === 0">
        <section class="section message">
          <p class="message-title">This is a place to manage related works and references.</p>
          <article class="message is-warning">
            <div class="message-header">
              <p>When would I use this?</p>
            </div>
            <div class="message-body">
              <p class="message-bullet">To keep all your article references in a single place.</p>
              <p class="message-bullet">To save video presentations, links and docs from existing works in the same research area than yours.</p>
              <p class="message-bullet">Generate BibTeX citations from your related papers.</p>
            </div>
          </article>
        </section>
      </div>

      <section v-if="allRelatedWorks.length !== 0" class="section items">
        <div class="columns header">
          <div class="column is-6">Name</div>
          <div class="column is-2">Last updated on</div>
          <div class="column is-2"></div>
          <div class="column is-2"></div>
        </div>

        <div
            v-for="relatedWork in allRelatedWorks"
            :key="relatedWork.id"
            class="items-grid">
          <div class="columns items">
            <div class="column is-6">
              {{ relatedWork.name}}
            </div>
            <div class="column is-2 date">
              {{ relatedWork.updated_at | formatDate }}
            </div>
            <div class="column is-2">
            </div>
            <div class="column is-2">
              <div @click="toggleOptionsDropdown(relatedWork.id)" class="dropdown" :class="{ 'is-active': relatedWork.selected }">
                <div class="dropdown-trigger">
                  <button class="button is-white is-light" aria-haspopup="true" aria-controls="dropdown-menu2">
                    <span>
                      <font-awesome-icon icon="ellipsis-h" class="icon-button"/>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content">
                    <div v-if="relatedWork.bibtex">
                      <a @click="copyBibTeX(relatedWork.bibtex)" class="dropdown-item">
                        <p><font-awesome-icon icon="copy" class="icon-button"/>Copy BibTex to Clipboard</p>
                      </a>
                      <hr class="dropdown-divider">
                    </div>
                    <a v-if="relatedWork.video_link" @click="playVideo(relatedWork.video_link)" class="dropdown-item">
                      <p><font-awesome-icon icon="video" class="icon-button"/>Open video presentation</p>
                    </a>
                    <a v-if="relatedWork.external_link" @click="openLink(relatedWork.external_link)" class="dropdown-item">
                      <p><font-awesome-icon icon="external-link-alt" class="icon-button"/>Open reference link</p>
                    </a>
                    <a v-if="relatedWork.file_link" @click="downloadFile(relatedWork.file_link)" class="dropdown-item">
                      <p><font-awesome-icon icon="download" class="icon-button"/>Download reference paper</p>
                    </a>
                    <hr class="dropdown-divider">
                    <a @click="editRelatedWork(relatedWork.id)" class="dropdown-item">
                      <p><font-awesome-icon icon="edit" class="icon-button"/>Edit</p>
                    </a>
                    <a @click="deleteRelatedWork(relatedWork.id)" class="dropdown-item">
                      <p><font-awesome-icon icon="trash" class="icon-button"/>Delete</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import router from '../../routes/router';
import {
	errorMessage,
	successMessage
} from '../../commons/constants';

import clipboard from 'clipboard';

export default {
	name: 'RelatedWorksList',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			fetchDataFail: false,
			loading: false,
			isOptionsDropdownActive: false,
			errorMessage: errorMessage.FAILED_FETCH,
			researchProject: {},
			relatedWorks: []
		};
	},
	computed: {
		allRelatedWorks() {
			return this.relatedWorks;
		}
	},
	async created() {
		this.$forceUpdate();

		this.loading = true;
		await this.getResearchProject();
		await this.getRelatedWorks();
		this.loading = false;
	},
	methods: {
		async getResearchProject() {
			const { ok, data } = await api.researchProjects.getById(this.$route.params.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.researchProject = data;
		},
		async getRelatedWorks() {
			const { ok, data } = await api.relatedWorks.getAllByResearchProjectId(this.researchProject.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.relatedWorks = data;
		},
		toggleNewDropdown() {
			this.isNewDropdownActive = !this.isNewDropdownActive;
		},
		toggleOptionsDropdown(id) {
			const indexOfItem = this.relatedWorks.indexOf(this.relatedWorks.filter((x) => (x.id === id))[0]);
			this.$set(this.relatedWorks[indexOfItem], 'selected', !this.relatedWorks[indexOfItem].selected);
		},
		copyBibTeX(bibTex) {
			clipboard.copy(bibTex);

			let timerInterval;
			Swal.fire({
				title: 'Citation Copied!',
				timer: 1300,
				timerProgressBar: true,
				didOpen: () => {
					Swal.showLoading();
					timerInterval = setInterval(() => {
					}, 100);
				},
				willClose: () => {
					clearInterval(timerInterval);
				}
			});
		},
		playVideo(link) {
			window.open(link);
		},
		openLink(link) {
			window.open(link);
		},
		downloadFile(link) {
			window.open(link);
		},
		goToNewRelatedWorkPage() {
			router.push({ name: 'RelatedWorksDetails', params: { researchProjectId: this.researchProject.id } });
		},
		editRelatedWork(id) {
			router.push({ name: 'RelatedWorksUpdate', params: { id: id, researchProjectId: this.researchProject.id } });
		},
		goToLabPage() {
			router.push({ path: `/lab/${this.researchProject.id}` });
		},
		async deleteRelatedWork(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You won\'t be able to revert this!',
				showCancelButton: true,
				confirmButtonColor: '#552fbc',
				cancelButtonColor: '#7957d5',
				confirmButtonText: 'Yes, delete it!'
			}).then(async(result) => {
				if (result.isConfirmed) {
					const { ok } = await api.relatedWorks.deleteRelatedWork(id);

					if (!ok) {
						Swal.fire(
							errorMessage.ERROR_POPUP_TITLE,
							errorMessage.DELETE_FAILED,
							'error'
						);
						return;
					}

					Swal.fire(
						successMessage.SUCCESS_POPUP_TITLE,
						successMessage.RELATED_WORK_DELETED_SUCCESS,
						'success'
					);

					await this.getRelatedWorks();
				}
			});
		}
	}
};
</script>

<style scoped>
.icon-button {
  margin-right: 5px;
}
.section.message {
  margin-top: -4rem;
  background-color: unset;
}
.message-title {
  margin-bottom: 2rem;
}
.message-bullet {
  margin-bottom: 1.3rem;
}
.section.items {
  margin-top: -4rem;
}
.header {
  font-size: 1.2rem;
}
.items {
  align-items: center;
}
.items-grid .link {
  vertical-align: top;
}
</style>
