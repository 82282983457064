<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <h2 class="title is-3">Article</h2>
        <hr />
      </section>

      <section class="section form">
        <div class="columns">
          <div class="column is-two-thirds">
            <div v-if="saveDataFail" class="notification is-danger is-light has-text-centered">
				{{ errorMessage }}
			</div>

            <div class="field">
              <label class="label">What do you want to call this link?</label>
              <div class="control">
                <input
                  v-model="article.name"
                  class="input"
                  type="text"
                  placeholder="Title of this file..."
                />
              </div>
              <p
                v-if="isFormInvalid"
                class="help is-danger"
              >
                Name cannot be empty
              </p>
            </div>

            <div class="field">
              <div v-if="fileSource ==='file_system'" class="file is-fullwidth has-name">
                <label class="file-label">
                  <input
                    @change="uploadFile"
                    class="file-input"
                    type="file"
                    id="file-input"
                    ref="files"
                  />
                  <span class="file-cta">
                    <span class="file-icon">
                      <font-awesome-icon class="icon-button" icon="upload" />
                    </span>
                    <span class="file-label">
                      Upload a file…
                    </span>
                  </span>
                  <span class="file-name">
                    {{ fileName }}
                  </span>
                </label>
              </div>

              <p
                v-if="isFileInvalid"
                class="help is-danger"
              >
                File size must not exceed 20 MB.
              </p>

              <p
                v-if="fileUploadFail"
                class="help is-danger"
              >
                Error uploading your file. Please try again.
              </p>

              <div class="field">
                <div class="label"></div>
                <div v-if="fileUploadStarted">
                  <progress v-if="!fileUploadSuccess" class="progress is-success is-primary" max="100">15%</progress>
                  <progress v-if="fileUploadSuccess" class="progress is-success" value="100" max="100">100%</progress>
                  <progress v-if="fileUploadFail" class="progress is-danger" value="90" max="100">90%</progress>
                </div>
              </div>

              <div v-if="fileSource !== 'file_system'">
                <label class="label">Paste the link here</label>
                <div class="control">
                  <p class="control has-icons-left">
                    <input
                      v-model="article.link"
                      class="input"
                      type="text"
                      :placeholder="'e.g.' + this.getLinkPlaceholder()"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon class="icon-button" v-if="fileSource == 'overleaf'" icon="leaf" />
                      <font-awesome-icon class="icon-button" v-if="fileSource == 'dropbox'" :icon="['fab', 'dropbox']" />
                      <font-awesome-icon class="icon-button" v-if="fileSource == 'google'" :icon="['fab', 'google-drive']" />
                      <font-awesome-icon class="icon-button" v-if="fileSource == 'other'" icon="link" />
                    </span>
                  </p>
                </div>
                <p
                  v-if="isFormInvalid"
                  class="help is-danger"
                >
                  Link cannot be empty
                </p>
              </div>
            </div>

            <div class="field">
              <label class="label">Notes (optional)</label>
              <div class="control">
                <textarea
                  v-model="article.description"
                  class="textarea"
                  placeholder="Doc brief description, or a note..."
                ></textarea>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button
                  @click="save"
                  class="button is-link"
                >
                  {{ this.$route.params.id ? 'Update this article' : 'Create this article' }}
                </button>
              </div>
              <div class="control">
                <button
                  @click="goToArticleListPage"
                  class="button is-link is-light"
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import humps from 'humps';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import {
	errorMessage,
	entityType,
	fileSource,
	fileValidation
} from '../../commons/constants';
import router from '../../routes/router';

export default {
	name: 'ArticleDetails',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			loading: false,
			saveDataFail: false,
			isFormInvalid: false,
			isFileInvalid: false,
			errorMessage: errorMessage.FAILED_SAVE,
			fileNameUploaded: 'e.g. initial-report.pdf',
			fileUploadFail: false,
			fileUploadSuccess: false,
			fileUploadStarted: false,
			article: {
				name: '',
				description: '',
				source: '',
				link: '',
				researchProjectId: ''
			}
		};
	},
	computed: {
		fileSource() {
			return this.$route.params.source;
		},
		fileName() {
			return this.fileNameUploaded;
		}
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadEntityData();
		}

		this.article.source = this.$route.params.source;
		this.article.researchProjectId = this.$route.params.researchProjectId;
	},
	methods: {
		validateForm() {
			this.isFormInvalid = false;

			if (this.article.name === undefined || this.article.name.length === 0) {
				this.isFormInvalid = true;
			}

			if (this.article.link === undefined || this.article.link.length === 0) {
				this.isFormInvalid = true;
			}

			return !this.isFormInvalid;
		},
		goToArticleListPage() {
			router.push({ path: `/articles/${this.article.researchProjectId}` });
		},
		async loadEntityData() {
			const { ok, data } = await api.articles.getById(this.$route.params.id);
			if (ok && data) {
				this.article = humps.camelizeKeys(data);
				/*
				* This is a workaround for extracting the file name from the S3 URL.
				* In the future: lets refactor the POST /article endpoint to save the original file name.
				* Then we could retrieve it from the GET /article/:id endpoint and remove this line.
				*/
				if (this.article.source === 'file_system' && this.article.link) {
					this.fileNameUploaded = this.article.link.split('/')[3].substring(33, this.article.link.length);
				}
			}
		},
		getLinkPlaceholder() {
			let placeholder = 'https://filewebsite.com/...';
			switch (this.$route.params.source) {
			case fileSource.GOOGLE:
				placeholder = 'https://docs.google.com/...';
				break;
			case fileSource.DROPBOX:
				placeholder = 'https://www.dropbox.com/home/...';
				break;
			case fileSource.OVERLEAF:
				placeholder = 'https://www.overleaf.com/project/...';
				break;
			default:
				break;
			}

			return placeholder;
		},
		async uploadFile() {
			this.fileUploadStarted = false;
			this.fileUploadSuccess = false;

			const fileData = this.$refs.files.files[0];
			this.fileNameUploaded = fileData.name;
			if (fileData.size > fileValidation.MAX_FILE_SIZE) {
				this.isFileInvalid = true;
				return;
			}

			this.fileUploadStarted = true;
			this.isFileInvalid = false;

			const formData = new FormData();
			formData.append('entityType', entityType.ARTICLE);
			formData.append('file', fileData);

			const { ok, data } = await api.files.upload(formData);
			if (!ok) {
				this.saveDataFail = true;
			}

			this.article.link = data.link;
			this.fileUploadSuccess = true;
		},
		async save() {
			if (!this.validateForm()) {
				return;
			}

			this.loading = true;
			if (!this.$route.params.id) {
				const { ok } = await api.articles.save(this.article);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your article was created!',
					'success'
				);
			} else {
				const { ok } = await api.articles.update(this.article);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your article was updated!',
					'success'
				);
			}

			this.loading = false;
			router.push({ path: `/articles/${this.article.researchProjectId}` });
		}
	}
};
</script>

<style scoped>
.form {
  margin-top: -4rem;
}
</style>
