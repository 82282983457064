<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
		<section class="section">
			<h2 class="title is-3">Conference</h2>
			<hr />
		</section>

		<section class="section form">
			<div class="columns">
				<div class="column is-two-thirds">
					<div v-if="saveDataFail" class="notification is-danger is-light has-text-centered">
						{{ errorMessage }}
					</div>

					<div class="field">
						<label class="label">What's the name of this conference?</label>
						<div class="control">
							<input
							v-model="conference.name"
							class="input"
							type="text"
							placeholder="e.g. IEEE Symposium on Computers and Communications"
							/>
						</div>
						<p
							v-if="isFormInvalid"
							class="help is-danger"
						>
							Name cannot be empty
						</p>
					</div>

					<div class="field">
						<label class="label">Conference home page link (optional)</label>
						<div class="control has-icons-left">
							<input
								v-model="conference.link"
								class="input"
								type="text"
								placeholder="https://iscc2022.unipi.gr/"
							/>
							<span class="icon is-small is-left">
								<font-awesome-icon class="icon-button" icon="link" />
							</span>
						</div>
					</div>

					<div class="columns">
						<div class="column is-two-forths">
							<div class="field">
								<label class="label">Papers due/deadline (optional)</label>
								<div class="control">
									<input
										v-model="conference.dueDate"
										class="input"
										type="date"
									/>
								</div>
							</div>
						</div>

						<div class="column is-two-forths">
							<div class="field">
								<label class="label">Conference presentation date (optional)</label>
								<div class="control">
									<input
										v-model="conference.presentationDate"
										class="input"
										type="date"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="field">
						<label class="label">Paper submitted?</label>
						<div class="control">
							<select v-model="conference.paperSubmissionStatus" class="input" name="result-status" id="result-status">
								<option value="NO">No, not yet</option>
								<option value="YES" selected="true">Yes</option>
							</select>
						</div>
					</div>

					<div class="columns">
						<div class="column is-two-forths">
							<div class="field">
								<label class="label">Registration fee (optional)</label>
								<div class="control has-icons-left">
									<input
										v-model="conference.fee"
										@change="formatFeeValue"
										class="input"
										type="text"
										placeholder="600,00"
									/>
									<span class="icon is-small is-left">
										<font-awesome-icon class="icon-button" icon="dollar-sign" />
									</span>
								</div>
							</div>
						</div>
						<div class="column is-two-forths">
							<div class="field">
								<label class="label">Registration fee status</label>
								<div class="control">
									<select v-model="conference.feeStatus" class="input" name="result-status" id="result-status">
										<option value="NOT_PAID">Not paid</option>
										<option value="PAID" selected="true">Paid</option>
										<option value="PENDING_REINBURSEMENT" selected="true">Paid, pending reimbursement</option>
										<option value="REIMBURSED" selected="true">Paid, reimbursed</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div v-if="conference.feeStatus !== 'NOT_PAID'" class="field">
						<label class="label">Payment receipt (optional)</label>
						<div class="file is-fullwidth has-name">
							<label class="file-label">
								<input
									@change="uploadFile"
									class="file-input"
									type="file"
									id="file-input"
									ref="files"
								/>
								<span class="file-cta">
									<span class="file-icon">
										<font-awesome-icon class="icon-button" icon="upload" />
									</span>
									<span class="file-label">
										Upload a file…
									</span>
								</span>
								<span class="file-name">
									{{ fileName }}
								</span>
							</label>
						</div>

						<p
							v-if="isFileInvalid"
							class="help is-danger"
						>
							File size must not exceed 20 MB.
						</p>

						<p
							v-if="fileUploadFail"
							class="help is-danger"
						>
							Error uploading your file. Please try again.
						</p>

						<div class="field">
							<div class="label"></div>
							<div v-if="fileUploadStarted">
								<progress v-if="!fileUploadSuccess" class="progress is-success is-primary" max="100">15%</progress>
								<progress v-if="fileUploadSuccess" class="progress is-success" value="100" max="100">100%</progress>
								<progress v-if="fileUploadFail" class="progress is-danger" value="90" max="100">90%</progress>
							</div>
						</div>
					</div>

					<!-- Paper info section -->
					<hr/>
					<h2 class="title is-4">Paper info</h2>

					<div class="field">
						<label class="label">Paper abstract (optional)</label>
						<div class="control">
							<textarea
								v-model="conference.abstract"
								class="textarea"
								placeholder="The abstract of the paper you'll submit..."
							></textarea>
						</div>
					</div>

					<div class="field">
						<label class="label">Authors, separated by semicoma <b>;</b> (optional)</label>
						<div class="control">
							<p class="control">
								<input
									v-model="conference.authors"
									class="input"
									type="text"
									placeholder="BLEME, L. A. C. ; Marcos Augusto M. Vieira ; VIEIRA, L. F. M"
								/>
							</p>
						</div>
					</div>

					<div class="field">
						<label class="label">Categories/keywords, separated by semicoma <b>;</b> (optional)</label>
						<div class="control">
							<p class="control">
								<input
									v-model="conference.categories"
									class="input"
									type="text"
									placeholder="Quality of service ; Scheduling algorithms ; Kernel ; Linux ; Bandwidth"
								/>
							</p>
						</div>
					</div>

					<div class="field">
						<label class="label">Slide deck link (optional)</label>
						<div class="control has-icons-left">
							<input
								v-model="conference.slideDeckLink"
								class="input"
								type="text"
								placeholder="e.g. https://docs.google.com/..."
							/>
							<span class="icon is-small is-left">
								<font-awesome-icon class="icon-button" icon="link" />
							</span>
						</div>
					</div>

					<!-- action buttons -->
					<div class="field is-grouped">
						<div class="control">
							<button
							@click="save"
							class="button is-link"
							>
							{{ this.$route.params.id ? 'Update this conference' : 'Create this conference' }}
							</button>
						</div>
						<div class="control">
							<button
							@click="goToConferenceListPage"
							class="button is-link is-light"
							>Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import humps from 'humps';
import moment from 'moment';

import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';

import {
	conferenceFeeStatus,
	conferencePaperSubmissionStatus,
	errorMessage,
	entityType,
	fileValidation
} from '../../commons/constants';

import { formatMoney } from '../../commons/formatMoney';

import router from '../../routes/router';

export default {
	name: 'ConferencesDetails',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			loading: false,
			saveDataFail: false,
			isFormInvalid: false,
			isFileInvalid: false,
			fileUploadFail: false,
			fileUploadStarted: false,
			fileUploadSuccess: false,
			fileNameUploaded: 'e.g. IEEE-ISCC-2022-receipt.pdf',
			errorMessage: errorMessage.FAILED_SAVE,
			conference: {
				name: '',
				link: '',
				dueDate: '',
				presentationDate: '',
				paperSubmissionStatus: conferencePaperSubmissionStatus.NO,
				fee: '',
				feeStatus: conferenceFeeStatus.NOT_PAID,

				abstract: '',
				authors: '',
				categories: '',
				researchProjectId: '',
				slideDeckLink: ''
			}
		};
	},
	computed: {
		fileName() {
			return this.fileNameUploaded;
		}
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadEntityData();
		}

		this.conference.researchProjectId = this.$route.params.researchProjectId;
	},
	methods: {
		validateForm() {
			this.isFormInvalid = false;

			if (this.conference.name === undefined || this.conference.name.length === 0) {
				this.isFormInvalid = true;
			}

			return !this.isFormInvalid;
		},
		async loadEntityData() {
			const { ok, data } = await api.conferences.getById(this.$route.params.id);
			if (ok && data) {
				this.conference = humps.camelizeKeys(data);
				if (this.conference.dueDate) {
					this.conference.dueDate = moment(this.conference.dueDate).format('YYYY-MM-DD');
				}

				if (this.conference.presentationDate) {
					this.conference.presentationDate = moment(this.conference.presentationDate).format('YYYY-MM-DD');
				}

				/*
				* This is a workaround for extracting the file name from the S3 URL.
				* In the future: lets refactor the POST /conferences endpoint to save the original file name.
				* Then we could retrieve it from the GET /conferences/:id endpoint and remove this line.
				*/
				if (this.conference.feeReceiptLink) {
					this.fileNameUploaded = this.conference.feeReceiptLink.split('/')[3].substring(33, this.conference.feeReceiptLink.length);
				}
			}
		},
		async uploadFile() {
			this.fileUploadStarted = false;
			this.fileUploadSuccess = false;

			const fileData = this.$refs.files.files[0];
			this.fileNameUploaded = fileData.name;
			if (fileData.size > fileValidation.MAX_FILE_SIZE) {
				this.isFileInvalid = true;
				return;
			}

			this.fileUploadStarted = true;
			this.isFileInvalid = false;

			const formData = new FormData();
			formData.append('entityType', entityType.CONFERENCE);
			formData.append('file', fileData);

			const { ok, data } = await api.files.upload(formData);
			if (!ok) {
				this.saveDataFail = true;
			}

			this.conference.feeReceiptLink = data.link;
			this.fileUploadSuccess = true;
		},
		async save() {
			if (!this.validateForm()) {
				return;
			}

			this.loading = true;
			if (!this.$route.params.id) {
				if (this.conference.dueDate) {
					this.conference.dueDate = new Date(this.conference.dueDate).toISOString();
				}

				if (this.conference.presentationDate) {
					this.conference.presentationDate = new Date(this.conference.presentationDate).toISOString();
				}

				const { ok } = await api.conferences.save(this.conference);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your conference was created!',
					'success'
				);
			} else {
				const { ok } = await api.conferences.update(this.conference);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your conference was updated!',
					'success'
				);
			}

			this.loading = false;
			router.push({ path: `/conferences/${this.conference.researchProjectId}` });
		},
		formatFeeValue() {
			this.conference.fee = formatMoney(this.conference.fee);
		},
		goToConferenceListPage() {
			router.push({ path: `/conferences/${this.conference.researchProjectId}` });
		}
	}
};
</script>

<style scoped>
.form {
  margin-top: -4rem;
}
</style>
