import * as base from '../base';

export async function getAllByResearchProjectId(id) {
	return await base.get(`conferences?researchProjectId=${id}`);
};

export async function getById(id) {
	return await base.get(`conferences/${id}`);
};

export async function save(conference) {
	return await base.post('conferences', conference);
};

export async function update(conference) {
	return await base.put(`conferences/${conference.id}`, conference);
};

export async function deleteConference(id) {
	return await base.deleteEndpoint(`conferences/${id}`);
};
