import * as articles from './endpoints/articles';
import * as conferences from './endpoints/conferences';
import * as experiments from './endpoints/experiments';
import * as files from './endpoints/files';
import * as relatedWorks from './endpoints/relatedWorks';
import * as researchProjects from './endpoints/researchProjects';
import * as user from './endpoints/user';

export default {
	articles,
	conferences,
	experiments,
	files,
	relatedWorks,
	researchProjects,
	user
};
