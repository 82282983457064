import * as base from '../base';

export async function getAllByResearchProjectId(id) {
	return await base.get(`articles?researchProjectId=${id}`);
};

export async function getById(id) {
	return await base.get(`articles/${id}`);
};

export async function save(article) {
	return await base.post('articles', article);
};

export async function update(article) {
	return await base.put(`articles/${article.id}`, article);
};

export async function deleteArticle(id) {
	return await base.deleteEndpoint(`articles/${id}`);
};
