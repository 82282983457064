import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store/store';

// Auth
import SignupPage from '../components/auth/SignUp.vue';
import SignInPage from '../components/auth/SignIn.vue';
import RecoverPasswordPage from '../components/auth/RecoverPassword.vue';
import ResetPasswordPage from '../components/auth/ResetPassword.vue';
import Paywall from '../components/auth/Paywall.vue';

import Home from '../components/home/Home.vue';
import ResearchProjectDetailsPage from '../components/researchProject/ResearchProjectDetails.vue';
import LabList from '../components/lab/LabList.vue';

// Articles
import ArticlesList from '../components/articles/ArticlesList.vue';
import ArticlesDetails from '../components/articles/ArticlesDetails.vue';

// Conferences
import ConferencesList from '../components/conferences/ConferencesList.vue';
import ConferencesDetails from '../components/conferences/ConferencesDetails.vue';

// Experiments
import ExperimentsList from '../components/experiments/ExperimentsList.vue';
import ExperimentsDetails from '../components/experiments/ExperimentsDetails.vue';
import ExperimentResultDetails from '../components/experiments/ExperimentsResultDetails.vue';

// Related Works
import RelatedWorksList from '../components/relatedWorks/RelatedWorksList.vue';
import RelatedWorksDetails from '../components/relatedWorks/RelatedWorksDetails.vue';

import SearchAssistantDetails from '../components/searchAssistant/SearchAssistantDetails.vue';

Vue.use(VueRouter);

const routes = [
	// Auth
	{ path: '/signup', component: SignupPage },
	{ path: '/signin', component: SignInPage },
	{ path: '/recover-password', component: RecoverPasswordPage },
	{ path: '/reset-password', component: ResetPasswordPage },
	{ path: '/paywall', component: Paywall },

	// Research Projects
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/research-project',
		name: 'ResearchProjectDetail',
		component: ResearchProjectDetailsPage,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/research-project/:id',
		name: 'ResearchProjectUpdate',
		component: ResearchProjectDetailsPage,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},

	// Lab
	{
		path: '/lab/:id',
		name: 'LabList',
		component: LabList,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},

	// Articles
	{
		path: '/articles/:id',
		name: 'ArticlesList',
		component: ArticlesList,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/article',
		name: 'ArticlesDetails',
		component: ArticlesDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/article/:id',
		name: 'ArticlesUpdate',
		component: ArticlesDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},

	// Related Works
	{
		path: '/related-works/:id',
		name: 'RelatedWorksList',
		component: RelatedWorksList,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/related-work',
		name: 'RelatedWorksDetails',
		component: RelatedWorksDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/related-work/:id',
		name: 'RelatedWorksUpdate',
		component: RelatedWorksDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},

	// Experiments
	{
		path: '/experiments/:id',
		name: 'ExperimentsList',
		component: ExperimentsList,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/experiments',
		name: 'ExperimentsDetails',
		component: ExperimentsDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/experiments/:id',
		name: 'ExperimentsUpdate',
		component: ExperimentsDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/experiments/result/:id',
		name: 'ExperimentsResultDetails',
		component: ExperimentResultDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/experiments/view-result/:id',
		name: 'ExperimentsResultView',
		props: { readOnly: true },
		component: ExperimentResultDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},

	// Conferences
	{
		path: '/conferences/:id',
		name: 'ConferencesList',
		component: ConferencesList,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/conferences',
		name: 'ConferencesDetails',
		component: ConferencesDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},
	{
		path: '/conferences/:id',
		name: 'ConferencesUpdate',
		component: ConferencesDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	},

	// Search Assistant
	{
		path: '/search-assistant',
		name: 'SearchAssistantDetails',
		component: SearchAssistantDetails,
		beforeEnter(to, from, next) {
			checkUserAuth(to, from, next);
		}
	}
];

function checkUserAuth(to, from, next) {
	if (store.state.user !== null && store.state.user.is_trial_expired) {
		next('/paywall');
		return;
	}

	if (store.state.token) {
		next();
	} else {
		next('/signin');
	}
};

export default new VueRouter({ mode: 'history', routes });
