<template>
  <section class="container">
    <meta name="google-signin-client_id" content="155531516805.apps.googleusercontent.com">

    <div class="columns is-multiline">
      <div class="column is-8 is-offset-2 register">
        <div class="columns">
          <div class="column left">
			<h1 class="title is-2">ResearchScale</h1>
            <h2 class="subtitle colored is-4">Get your research organized</h2>
            <p>
				Have everything in <b>one place</b>, not all over the place.
			</p>
			<br/>
			<p>
				No credit card required.
			</p>
          </div>

          <div class="column right has-text-centered">
            <div v-if="signUpFailed" class="notification is-danger is-light has-text-centered">
              {{ errorMessage }}
            </div>
            <h1 class="title is-3">Sign up today</h1>
            <p class="description">
              <button @click="goToSignInPage" class="button is-ghost">
                Already have an account? Sign In
              </button>
            </p>
            <form @submit.prevent="onSubmit">
              <div class="field">
                <div class="control">
                  <input
                    v-model="name"
                    class="input is-medium"
                    type="text"
                    required="true"
                    placeholder="Name"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    v-model="email"
                    class="input is-medium"
                    type="email"
                    required="true"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    v-model="password"
                    class="input is-medium"
                    type="password"
                    required="true"
                    placeholder="Password"
                  />
                </div>
              </div>
              <button class="button is-block is-primary is-fullwidth is-medium" type="submit">Sign Up</button>
              <br />
            </form>
          </div>
        </div>
      </div>
      <div class="column is-8 is-offset-2">
        <br>
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <span class="icon">
                <i class="fab fa-twitter"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fab fa-github"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="level-right">
            <small @click="goToLandingPage" class="level-item" style="color: var(--textLight); cursor: pointer">
              &copy; ResearchScale. All Rights Reserved.
            </small>
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>
<script>
import router from '../../routes/router';
import api from '../../api';
import {
	httpStatusCode,
	errorMessage
} from '../../commons/constants';

export default {
	data() {
		return {
			name: '',
			email: '',
			password: '',
			signUpFailed: false,
			errorMessage: errorMessage.USER_SIGNUP_FAILED
		};
	},
	methods: {
		async onSubmit() {
			if (this.password.length < 6) {
				this.signUpFailed = true;
				this.errorMessage = errorMessage.USER_PASSWORD_TOO_SHORT;
				return;
			}

			const { ok, statusCode, data } = await api.user.signUp({
				name: this.name,
				email: this.email,
				password: this.password
			});
			if (!ok) {
				this.signUpFailed = true;

				if (statusCode === httpStatusCode.BAD_REQUEST) {
					this.errorMessage = errorMessage.USER_ALREADY_EXIST;
				}
				return;
			}

			this.$store.dispatch('signup', data);

			const userRequest = await api.user.getById(data.userId);
			if (!userRequest.ok) {
				return;
			}

			this.$store.dispatch('storeUser', userRequest.data);

			router.push({ path: '/' });
		},
		goToSignInPage() {
			router.push({ path: '/signin' });
		},
		goToLandingPage() {
			window.open('https://researchscale.io');
		}
	}
};
</script>
<style scoped>
.field:not(:last-child) {
  margin-bottom: 1rem;
}

.register {
  margin-top: 10rem;
  background: white;
  border-radius: 10px;
}

.left,
.right {
  padding: 4.5rem;
}

.left {
  border-right: 5px solid var(--background);
}

.left .title {
  font-weight: 800;
  letter-spacing: -2px;
}

.left .colored {
  color: var(--brandColor);
  font-weight: 500;
  margin-top: 1rem !important;
  letter-spacing: -1px;
}

.left p {
  color: var(--textLight);
  font-size: 1.15rem;
}

.right .title {
  font-weight: 800;
  letter-spacing: -1px;
}

.right .description {
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  color: var(--textLight);
  font-size: 1.15rem;
}

.right small {
  color: var(--textLight);
}

input {
  font-size: 1rem;
}

input:focus {
  border-color: var(--brandColor) !important;
  box-shadow: 0 0 0 1px var(--brandColor) !important;
}

.fab,
.fas {
  color: var(--textLight);
  margin-right: 1rem;
}
</style>
