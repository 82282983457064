<template>
<div>
	<div v-if="isUserOnTrial === true" class="notification is-warning is-light">
		<p class="trial-notification-content">Your free trial ends on {{ userTrialExpirationDate | formatDateForNotification }}.
			<a :href="paymentLink" target="_blank"><strong>Buy ResearchScale today!</strong></a>
		</p>
	</div>

	<div v-if="isUserTrialExpired === true" class="notification is-danger is-light">
		<p class="trial-notification-content">Your free trial ended on {{ userTrialExpirationDate | formatDateForNotification }}.
			<a :href="paymentLink" target="_blank"><strong>Buy ResearchScale today</strong></a> to access all features.
		</p>
	</div>

	<nav v-if="auth" class="navbar" role="navigation" aria-label="main navigation">
		<div class="navbar-brand">
		<a class="navbar-item" href="#">
			<!-- ResearchScale -->
			<h2 class="subtitle">
				<router-link
					to="/">
					<strong>ResearchScale</strong> <span class="tag is-primary is-light is-small">Beta</span>
				</router-link>
			</h2>

			<!-- <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28"> -->
		</a>

		<a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
			<span aria-hidden="true"></span>
			<span aria-hidden="true"></span>
			<span aria-hidden="true"></span>
		</a>
		</div>

		<div id="navbarBasicExample" class="navbar-menu">
		<div class="navbar-start">
			<router-link
				to="/"
				class="navbar-item">
					Home
			</router-link>
			<router-link
				to="/search-assistant"
				class="navbar-item">
					Search Assistant ✨
			</router-link>
		</div>

		<div class="navbar-end">
			<div class="navbar-item">
			<div class="navbar-item has-dropdown is-hoverable">
			<a v-if="user" class="navbar-link">
				{{ user.name }}
			</a>

			<div class="navbar-dropdown">
				<a :href="customerPortalLink" target="_blank" class="navbar-item">
					<span>
						Manage subscription
					</span>
				</a>
				<a href="mailto:support@researchscale.io" class="navbar-item">
					<span>
						I need a hand
					</span>
				</a>
				<hr class="navbar-divider">
				<a href="mailto:support@researchscale.io" class="navbar-item">
					<span>
						<font-awesome-icon icon="comment" class="icon-button"/> Feedback
					</span>
				</a>
			</div>
			</div>
			<div class="buttons">
				<!-- <a class="button is-primary">
				<strong>Sign up</strong>
				</a> -->
				<a @click="onLogout" class="button is-light">
				Log out
				</a>
			</div>
			</div>
		</div>
		</div>
	</nav>
</div>
</template>

<script>
import router from '../../routes/router';
import { stripePaymentLink, stripeCustomerPortalLink } from '../../commons/constants';

export default {
	computed: {
		auth() {
			return this.$store.getters.isAuthenticated;
		},
		user() {
			return this.$store.state.user;
		},
		isUserOnTrial() {
			if (!this.$store.state.user) {
				return false;
			}
			return this.$store.state.user.is_on_trial === true;
		},
		userTrialExpirationDate() {
			if (!this.$store.state.user) {
				return;
			}
			return this.$store.state.user.trial_expires_at;
		},
		isUserTrialExpired() {
			if (!this.$store.state.user) {
				return false;
			}
			return this.$store.state.user.is_trial_expired === true;
		},
		paymentLink() {
			if (!this.$store.state.user) {
				return;
			}
			return stripePaymentLink.replace('%user_email%', this.user.email);
		},
		customerPortalLink() {
			return stripeCustomerPortalLink;
		}
	},
	methods: {
		onLogout() {
			this.$store.dispatch('logout');
			router.push({ path: '/signin' });
		}
	}
};
</script>

<style scoped>
.notification.is-warning.is-light {
	margin-bottom: 0px;
    font-size: 0.94em;
    line-height: 0.7em;
}
.notification.is-danger.is-light {
	margin-bottom: 0px;
    font-size: 0.94em;
    line-height: 0.7em;
}
.trial-notification-content {
  text-align: center;
}
</style>
