<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <h2 class="title is-3">Research project</h2>
        <hr />
      </section>

      <section class="section form">
        <div class="columns">
          <div class="column is-two-thirds">
            <div v-if="saveDataFail" class="notification is-danger is-light has-text-centered">
              {{ errorMessage }}
          </div>

            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  v-model="researchProject.name"
                  class="input"
                  type="text"
                  placeholder="Your research title here"
                />
              </div>
              <p
                v-if="isFormInvalid"
                class="help is-danger"
              >
                Name cannot be empty
              </p>
            </div>

            <div class="field">
              <label class="label">Description (optional)</label>
              <div class="control">
                <textarea
                  v-model="researchProject.description"
                  class="textarea"
                  placeholder="Paper abstract, or a brief description..."
                ></textarea>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button
                  @click="save"
                  class="button is-link"
                >
                  {{ this.$route.params.id ? 'Update this project' : 'Create this project' }}
                </button>
              </div>
              <div class="control">
                <button
                  @click="goToHomePage"
                  class="button is-link is-light"
                >Cancel</button>
              </div>
            </div>
          </div>
          </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import { errorMessage } from '../../commons/constants';
import router from '../../routes/router';

export default {
	name: 'ResearchProjectDetails',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			loading: false,
			saveDataFail: false,
			isFormInvalid: false,
			errorMessage: errorMessage.FAILED_SAVE,
			researchProject: {
				name: '',
				description: ''
			}
		};
	},
	async created() {
		if (this.$route.params.id) {
			await this.loadEntityData();
		}
	},
	methods: {
		validateForm() {
			this.isFormInvalid = false;

			if (this.researchProject.name === undefined || this.researchProject.name.length === 0) {
				this.isFormInvalid = true;
			}

			return !this.isFormInvalid;
		},
		goToHomePage() {
			router.push({ path: '/' });
		},
		async loadEntityData() {
			const { ok, data } = await api.researchProjects.getById(this.$route.params.id);
			if (ok && data) {
				this.researchProject = data;
			}
		},
		async save() {
			if (!this.validateForm()) {
				return;
			}

			this.loading = true;
			if (!this.$route.params.id) {
				const { ok } = await api.researchProjects.save(this.researchProject);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your project was created!',
					'success'
				);
			} else {
				const { ok } = await api.researchProjects.update(this.researchProject);
				if (!ok) {
					this.saveDataFail = true;
					this.loading = false;
					return;
				}

				Swal.fire(
					'Success!',
					'Your project was updated!',
					'success'
				);
			}

			this.loading = false;
			router.push({ path: '/' });
		}
	}
};
</script>

<style scoped>
.form {
  margin-top: -4rem;
}
</style>
