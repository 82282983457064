<template>
  <div>
    <loading-spinner :isLoading="loading" />

    <section v-if="!loading" class="container">
      <section class="section">
        <div v-if="fetchDataFail" class="notification is-danger is-light has-text-centered">
            {{ errorMessage }}
        </div>

        <h2 class="title is-3">Conferences</h2>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="goToLabPage"><font-awesome-icon icon="arrow-left" class="icon-button"/> {{ researchProject.name }}</a></li>
            <li class="is-active"><a href="#" aria-current="page">Conferences</a></li>
          </ul>
        </nav>
        <hr />

        <button
          @click="goToNewConferencePage"
          class="button is-primary is-light"
        >
          <font-awesome-icon icon="plus" class="icon-button"/> New
        </button>
      </section>

      <div v-if="allConferences.length === 0">
        <section class="section message">
          <p class="message-title">This is a place to manage conferences.</p>
          <article class="message is-warning">
            <div class="message-header">
              <p>When would I use this?</p>
            </div>
            <div class="message-body">
              <p class="message-bullet">To manage submitions to conferences and journals.</p>
              <p class="message-bullet">Track deadlines and fees.</p>
              <p class="message-bullet">Keep your conference presentation decks.</p>
            </div>
          </article>
        </section>
      </div>

      <section v-if="allConferences.length !== 0" class="section items">
		<div class="columns header">
			<div class="column is-4">Name</div>
			<div class="column is-2">Deadline</div>
			<div class="column is-2">Paper submitted</div>
			<div class="column is-2"></div>
			<div class="column is-2"></div>
		</div>

        <div
            v-for="conference in allConferences"
            :key="conference.id"
            class="items-grid">
          <div class="columns items">
            <div class="column is-4">
              {{ conference.name }}
            </div>
            <div class="column is-2">
				<span v-if="conference.dueDate">
					{{ conference.dueDate | formatDate }}
				</span>
				<span v-else>
					N/A
				</span>
            </div>
            <div class="column is-2 date">
				<span v-if="conference.paperSubmissionStatus === 'YES'" class="tag is-success">
					Yes
				</span>
				<span v-else class="tag is-warning">
					No
				</span>
            </div>
			<div class="column is-2">
              <button class="button is-light" @click="addToCalendar(conference)">
              <font-awesome-icon class="icon-button" icon="calendar" />
                Add to calendar
              </button>
            </div>
            <div class="column is-2">
              <div @click="toggleOptionsDropdown(conference.id)" class="dropdown" :class="{ 'is-active': conference.selected }">
                <div class="dropdown-trigger">
                  <button class="button is-white is-light" aria-haspopup="true" aria-controls="dropdown-menu2">
                    <span>
                      <font-awesome-icon icon="ellipsis-h" class="icon-button"/>
                    </span>
                  </button>
                </div>

				<!-- options menu -->
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
					<div class="dropdown-content">
						<!-- result data -->
						<div v-if="conference.feeReceiptLink">
							<a
								@click="downloadReceipt(conference.feeReceiptLink)"
								class="dropdown-item"
							>
								<p><font-awesome-icon icon="download" class="icon-button"/>Download receipt</p>
							</a>
						</div>

						<div v-if="conference.slideDeckLink">
							<a
								@click="openPresentationDeck(conference.slideDeckLink)"
								class="dropdown-item"
							>
								<p><font-awesome-icon icon="external-link-alt" class="icon-button"/>Open slide deck</p>
							</a>
						</div>

						<hr
							v-if="conference.feeReceiptLink || conference.slideDeckLink"
							class="dropdown-divider"
						/>

						<!-- update and delete -->
						<a @click="editConference(conference.id)" class="dropdown-item">
							<p><font-awesome-icon icon="edit" class="icon-button"/>Edit</p>
						</a>
						<a @click="deleteConference(conference.id)" class="dropdown-item">
							<p><font-awesome-icon icon="trash" class="icon-button"/>Delete</p>
						</a>
					</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import humps from 'humps';
import LoadingSpinner from '../commons/LoadingSpinner.vue';

import api from '../../api';
import router from '../../routes/router';
import {
	errorMessage,
	successMessage
} from '../../commons/constants';

export default {
	name: 'ConferencesList',
	components: {
		'loading-spinner': LoadingSpinner
	},
	data() {
		return {
			fetchDataFail: false,
			loading: false,
			isOptionsDropdownActive: false,
			errorMessage: errorMessage.FAILED_FETCH,
			researchProject: {},
			conferences: []
		};
	},
	computed: {
		allConferences() {
			return this.conferences;
		}
	},
	async created() {
		this.$forceUpdate();

		this.loading = true;
		await this.getResearchProject();
		await this.getConferences();
		this.loading = false;
	},
	methods: {
		async getResearchProject() {
			const { ok, data } = await api.researchProjects.getById(this.$route.params.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.researchProject = data;
		},
		async getConferences() {
			const { ok, data } = await api.conferences.getAllByResearchProjectId(this.researchProject.id);
			if (!ok) {
				this.fetchDataFail = true;
				return;
			}

			this.conferences = humps.camelizeKeys(data);
		},
		toggleNewDropdown() {
			this.isNewDropdownActive = !this.isNewDropdownActive;
		},
		toggleOptionsDropdown(id) {
			const indexOfItem = this.conferences.indexOf(this.conferences.filter((x) => (x.id === id))[0]);
			this.$set(this.conferences[indexOfItem], 'selected', !this.conferences[indexOfItem].selected);
		},
		goToNewConferencePage() {
			router.push({ name: 'ConferencesDetails', params: { researchProjectId: this.researchProject.id } });
		},
		editConference(id) {
			router.push({ name: 'ConferencesUpdate', params: { id: id, researchProjectId: this.researchProject.id } });
		},
		goToLabPage() {
			router.push({ path: `/lab/${this.researchProject.id}` });
		},
		downloadReceipt(link) {
			window.open(link);
		},
		openPresentationDeck(link) {
			window.open(link);
		},
		addToCalendar(conference) {
			if (!conference.dueDate && !conference.presentationDate) {
				Swal.fire(
					errorMessage.ERROR_POPUP_TITLE,
					errorMessage.FAILED_ADD_TO_CALENDAR,
					'info'
				);
				return;
			}

			const eventName = conference.name.replace(' ', '+');
			const eventDescription = `Deadline+to+submit+paper,+link:+https://researchscale.io/conferences/${conference.id}`;
			let eventDate = conference.dueDate;
			if (!conference.dueDate) {
				eventDate = conference.presentationDate;
			}
			eventDate = (new Date(eventDate)).toISOString().replace(/-|:|\.\d\d\d/g, '');

			const googleCalendarURL = `https://www.google.com/calendar/render?action=TEMPLATE&text=[DEADLINE]+${eventName}&dates=${eventDate}/${eventDate}&details=${eventDescription}&sf=true&output=xml`;
			window.open(googleCalendarURL);
		},
		async deleteConference(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'You won\'t be able to revert this!',
				showCancelButton: true,
				confirmButtonColor: '#552fbc',
				cancelButtonColor: '#7957d5',
				confirmButtonText: 'Yes, delete it!'
			}).then(async(result) => {
				if (result.isConfirmed) {
					const { ok } = await api.conferences.deleteConference(id);

					if (!ok) {
						Swal.fire(
							errorMessage.ERROR_POPUP_TITLE,
							errorMessage.DELETE_FAILED,
							'error'
						);
						return;
					}

					Swal.fire(
						successMessage.SUCCESS_POPUP_TITLE,
						successMessage.CONFERENCE_DELETED_SUCCESS,
						'success'
					);

					await this.getConferences();
				}
			});
		}
	}
};
</script>

<style scoped>
.icon-button {
  margin-right: 5px;
}
.section.message {
  margin-top: -4rem;
  background-color: unset;
}
.message-title {
  margin-bottom: 2rem;
}
.message-bullet {
  margin-bottom: 1.3rem;
}
.section.items {
  margin-top: -4rem;
}
.header {
  font-size: 1.2rem;
}
.items {
  align-items: center;
}
.items-grid .link {
  vertical-align: top;
}
</style>
