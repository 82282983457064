import * as base from '../base';

export async function getAllByResearchProjectId(id) {
	return await base.get(`experiments?researchProjectId=${id}`);
};

export async function getById(id) {
	return await base.get(`experiments/${id}`);
};

export async function save(experiment) {
	return await base.post('experiments', experiment);
};

export async function start(experiment) {
	return await base.put(`experiments/${experiment.id}/start`, experiment);
};

export async function finish(experiment) {
	return await base.put(`experiments/${experiment.id}/finish`, experiment);
};

export async function update(experiment) {
	return await base.put(`experiments/${experiment.id}`, experiment);
};

export async function deleteExperiment(id) {
	return await base.deleteEndpoint(`experiments/${id}`);
};
